import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import DashboardView from "../views/dashboard/DashboardView";
import MyAccountView from "../views/dashboard/MyAccountView";
import SettingsView from "../views/dashboard/SettingsView";
import StatisticsView from "../views/dashboard/StatisticsView";
import SocialUsersView from "../views/dashboard/SocialUsersView";
import SocialPostsView from "../views/dashboard/SocialPostsView";
import LoginView from "../views/LoginView";
import NotFoundView from "../views/NotFoundView";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: LoginView,
  },
  {
    path: "/about",
    name: "About",
    component: LoginView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/my-account",
    name: "myaccount",
    component: MyAccountView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: StatisticsView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/social-users",
    name: "SocialUsersView",
    component: SocialUsersView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/social-posts",
    name: "SocialPostsView",
    component: SocialPostsView,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:catchAll(.*)", // Match all paths
    component: NotFoundView, // Render the NotFound component
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/" || to.path === "/login") {
    // If navigating to home or login
    if (store.state.isAuthenticated) {
      // If user is authenticated
      next("/dashboard"); // Redirect to dashboard
    } else {
      next(); // Continue navigation to login view
    }
  } else if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    next("/login");
  } else {
    next();
  }
});
export default router;
