<template>
  <div
    :class="{
      flex: $store.state.isAuthenticated === true,
      'bg-gray-50': true,
    }"
  >
    <template v-if="$store.state.isAuthenticated">
      <NavigationView />
    </template>
    <template v-else>
      <!-- <nav
        :class="{
          'pr-[313px]': $route.name === 'Dashboard',
          'bg-white': true,
          'py-5': true,
          'text-gray-700': true,
          'border-b': true,
        }"
      >
        <div class="container mx-auto">
          <router-link to="/">الرئيسية</router-link> |
          <router-link to="/about">About</router-link> |
          <router-link
            class="bg-indigo-500 text-white rounded-md p-2"
            to="/login"
            >Login</router-link
          >
        </div>
      </nav> -->
    </template>

    <section
      class="container mx-auto lg:max-w-screen-mlg mxl:max-w-screen-lg xl:max-w-screen-lg xxl:max-w-screen-xl 2xl:max-w-screen-2xl"
    >
      <div class="p-5">
        <router-view />
      </div>
      <!-- <footer>
        <p class="text-center">copy rights</p>
      </footer> -->
    </section>
  </div>
</template>
<script>
import NavigationView from "./views/dashboard/NavigationView.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    NavigationView,
  },
  // life cycle hook
  beforeCreate() {
    this.$store.commit("initializeStore");

    const token = this.$store.state.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
};
</script>
<style lang="scss"></style>
