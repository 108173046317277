<template>
  <div class="flex items-center" v-if="popularity">
    <div
      :class="{
        'flex items-start gap-2 px-2 text-gray-500 p-1 flex-1 cursor-text': true,
        'flex-col justify-center': popularity.length > 2,
        'flex-row justify-start': popularity.length < 2,
      }"
      v-for="(popular, index) in popularity"
      :key="index"
    >
      <div
        :class="{
          'flex flex-row justify-center items-center text-sm': true,
          'w-full': popularity.length > 2,
        }"
      >
        <font-awesome-icon
          :icon="getCustomOption(popular.name, 'icon')"
          :class="getCustomOption(popular.name, 'class')"
        />
        <span class="ms-2">{{ popular.count }}</span>
      </div>
      <span class="flex items-center justify-center text-[15px]">
        {{ getCustomOption(popular.name, "translation") }}
      </span>
    </div>
  </div>
</template>

<script>
import { getCustomOption } from "@/views/dashboard/helpers/helpers";
export default {
  name: "PostPopularity",
  props: {
    popularity: Array,
  },

  setup() {
    return { getCustomOption };
  },
};
</script>
