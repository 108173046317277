<template>
  <div
    class="relative w-full min-h-96 mb-5 group rounded-lg overflow-hidden shadow-lg ring-1 ring-gray-200"
  >
    <div :class="['title p-3 mb-3', color]">
      <h2
        class="text-lg font-semibold flex flex-row justify-start items-center"
      >
        <font-awesome-icon :icon="icon" class="me-2 text-xl" />
        {{ title }}
      </h2>
    </div>
    <div class="w-full p-5">
      <div id="chart">
        <apexchart
          type="bar"
          height="450"
          :options="chartOptions"
          :series="series"
          class="font-taj"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { getCustomOption } from "@/views/dashboard/helpers/helpers";
export default {
  name: "PostsByDayChart",
  props: {
    title: {
      type: String,
      default: "العنوان",
    },
    icon: {
      type: String,
      default: "fa-solid fa-globe",
    },
    color: {
      type: String,
      default: "bg-primary text-white",
    },
    days: Array,
    daysCount: Array,
  },
  setup(props) {
    const series = ref([
      {
        name: "عدد المرات",
        data: [],
      },
    ]);
    const chartOptions = {
      chart: {
        height: 450,
        type: "bar",
        offsetY: 15,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: "45%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
          strokeWidth: 50,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "";
        },
        offsetY: -30,
        style: {
          fontSize: "16px",
          colors: ["#304758"],
          fontFamily: "Tajawal",
        },
      },

      xaxis: {
        categories: [],
        position: "left",
        style: {
          fontSize: "32px",
          colors: ["#304758"],
          fontFamily: "Tajawal",
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Tajawal",
          },
        },
        labels: {
          style: {
            fontFamily: "Tajawal",
            fontSize: "18px",
            fontWeight: "500",
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          fontFamily: "Tajawal",
          formatter: function (val) {
            return val + "";
          },
        },
      },
      // title: {
      //   text: "عدد المقالات لكل يوم في الاسبوع",
      //   floating: true,
      //   offsetY: 425,
      //   align: "center",
      //   position: "top",
      //   style: {
      //     color: "#444",
      //     fontSize: "16px",
      //     fontFamily: "Tajawal",
      //   },
      // },
      fill: {
        colors: ["#14532D"],
      },
    };

    function updateOptions() {
      series.value[0].data = props.daysCount;
      const days = getCustomOption(props.days, "translation");
      chartOptions.xaxis.categories = days;
    }

    onMounted(() => {
      updateOptions();
    });
    watch(props, () => {
      updateOptions();
    });
    return { series, chartOptions, getCustomOption };
  },
};
</script>
