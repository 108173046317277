<template>
  <div class="dashboard">
    <div class="mx-auto">
      <div class="title my-5">
        <!-- <h1 class="text-3xl text-center">welcome {{ username }}</h1> -->
        <!-- {{ counts }}
        <br />
        {{ storedCounts }} -->
        <SearchBar :results="fetchResults" :query="searchQuery" />
        <div class="content text-center py-5">
          <section class="mb-32 text-center">
            <div class="tabs">
              <TabView class="tabview-custom font-taj" :active-index="1">
                <TabPanel v-for="(network, index) in posts" :key="index">
                  <template #header>
                    <div
                      :class="[
                        'flex items-center flex-1 flex-row-reverse gap-2 tabview-custom-header',
                        network.options.name,
                      ]"
                      :style="{
                        '--color': network.options.color,
                        '--border': network.options.border,
                      }"
                    >
                      <font-awesome-icon :icon="network.icon" />
                      <span class="font-bold white-space-nowrap capitalize"
                        >{{ network.title }}
                      </span>
                    </div>
                  </template>
                  <div
                    class="loading-screen m-0 min-h-[70vh] py-5 relative"
                    v-if="network.loading"
                  >
                    <LoadingNetworkScreen />
                  </div>
                  <div class="m-0 min-h-[70vh] py-5" v-else>
                    <!-- {{ network.data }} -->
                    <div
                      v-if="network.data && network.data.length > 0"
                      class="flex flex-wrap items-center justify-center sm:block columns-1 gap-5 sm:columns-2 sm:gap-8 lg:columns-3 xxl:columns-4"
                    >
                      <div
                        class="post-body"
                        v-for="(post, index) in network.data"
                        :key="index"
                      >
                        <div
                          class="max-w-sm rounded overflow-hidden shadow-md bg-gray-50 mb-5 text-right relative"
                          v-if="post"
                        >
                          <PostType
                            :postType="post.type"
                            :postImage="post.image"
                            :postLang="post.lang"
                            v-if="post.type || post.lang"
                          />
                          <PostImage
                            :postImage="post.image"
                            :postTitle="post.title"
                            :postText="post.text"
                            v-if="post.image"
                          />
                          <div class="px-6 pt-4" :bbb="post.postid">
                            <PostContent
                              :posted="post.posted"
                              :postTitle="post.title"
                              :postText="post.text"
                            />
                            <div class="meta">
                              <PostUser
                                :postUser="post.user"
                                :selectedUser="showUser"
                                :userToShow="userToShow"
                                :visiblity="visible"
                                v-if="post.user"
                              />
                              <PostTags
                                :postTags="post.tags"
                                v-if="post.tags"
                              />
                            </div>
                            <div
                              class="border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50 text-center"
                              v-if="post.sentiment"
                            >
                              <div class="sentiments-container">
                                <SentimentSelector
                                  :sentimentValue="post.sentiment"
                                  :sentimentClass="`${post.sentiment}-container`"
                                  :currentPostID="post.postid"
                                  :currentPostNetwork="`${post.network}Posts`"
                                  :sentimentHandler="handleSentimentUpdate"
                                />
                              </div>
                            </div>
                            <div
                              class="border-t-2 border-neutral-100 py-3 dark:border-neutral-600 dark:text-neutral-50 text-center"
                            >
                              <PostPopularity
                                :popularity="post.popularity"
                                v-if="post.popularity"
                              />
                              <PostUrl :url="post.url" v-if="post.url" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="text-center flex justify-center items-center min-h-[70vh] text-3xl font-medium"
                    >
                      لا يوجد بيانات في هذا المصدر
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import { ref, computed, watch } from "vue";
import "moment/locale/ar"; // Import the Arabic locale file
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import moment from "moment";
import SentimentSelector from "@/components/dashboard/SentimentSelector.vue";
import SearchBar from "@/components/dashboard/SearchBar.vue";
import PostType from "@/components/dashboard/post/PostType.vue";
import PostImage from "@/components/dashboard/post/PostImage.vue";
import PostContent from "@/components/dashboard/post/PostContent.vue";
import PostUser from "@/components/dashboard/post/PostUser.vue";
import PostTags from "@/components/dashboard/post/PostTags.vue";
import PostPopularity from "@/components/dashboard/post/PostPopularity.vue";
import PostUrl from "@/components/dashboard/post/PostUrl.vue";
import LoadingNetworkScreen from "@/components/dashboard/LoadingNetworkScreen.vue";
import {
  getPostsInitializer,
  getStoredPosts,
  retrieveItemFromLocalStorage,
  clearLocalStorage,
  getNetworksList,
} from "./helpers/helpers";
import {
  fetchDataAndAssignToTarget,
  getPosts,
  getPremiumPosts,
} from "./helpers/api";
</script>
<script>
const counts = ref(0);
export { counts };
export default {
  name: "DashboardView",
  data() {
    return {
      username: null,
      posts: getPostsInitializer(),
      searchQuery: "",
      visible: false,
      userToShow: {},
      finished: false,
      storedCounts: "",
    };
  },
  computed: {
    isFinished() {
      return this.finished;
    },
  },

  mounted() {
    // Make an HTTP request to fetch user data
    fetchDataAndAssignToTarget("/api/v1/users/", this, "username");
    // Set the locale to Arabic
    moment.locale("ar");
    // load searchQuery from local storage
    retrieveItemFromLocalStorage("searchQuery", this);
    this.storedCounts = localStorage.getItem("counts");
    // retrieve the stored posts in local storage
    getStoredPosts(this.posts);
    // console.log(this.isFinished);
  },
  methods: {
    handleSentimentUpdate(id, value, network) {
      // This method will be executed when the updateSentiment function is called in the child component
      if (this.posts[network] && this.posts[network].data) {
        // Create shallow copy of posts
        const postsCopy = { ...this.posts };
        // Find the post data with the specified post ID
        const postData = postsCopy[network].data.find(
          (post) => post.postid === id
        );
        if (postData) {
          postData.sentiment = value;
          // Save the updated posts object to local storage
          localStorage.setItem(
            network,
            JSON.stringify(postsCopy[network].data)
          );
        }
      }
    },
    fetchResults(query) {
      // clearing local storage
      localStorage.removeItem("searchQuery");
      localStorage.removeItem("counts");
      clearLocalStorage("posts", this);
      // list of regular networks
      const networks = getNetworksList();
      // Iterates over the list of networks and fetches search results for each network.

      networks.forEach((network) => {
        const container = `${network}Posts`;
        getPosts(this, "search", network, container, query);
      });
      // list of premium networks
      const premiumNetworks = getNetworksList(true);
      // Iterates over the list of networks and fetches search results for each network.
      premiumNetworks.forEach((network) => {
        const container = `${network}Posts`;
        getPremiumPosts(this, "search", network, container, query, counts);
      });
    },
    showUser(user) {
      this.userToShow = user;
      this.visible = true;
    },
  },
  watch: {
    counts: {
      handler(newVal, oldVal) {
        console.log("Counts:", oldVal);
        console.log("Counts changed:", newVal);
        // You can add any additional logic here based on the new value of counts
      },
    },
  },
};
// Export the ref
export const finishedRef = ref(false);

// Create a computed property to export
export const isFinishedComputed = computed(() => finishedRef.value);
</script>
