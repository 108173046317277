<template>
  <div
    class="relative w-full min-h-96 mb-5 group rounded-lg overflow-hidden shadow-lg ring-1 ring-gray-200"
    v-if="tags.length > 0"
  >
    <div :class="['title p-3 mb-3', color]">
      <h2
        class="text-lg font-semibold flex flex-row justify-start items-center"
      >
        <font-awesome-icon :icon="icon" class="me-2 text-xl" />
        {{ title }}
      </h2>
    </div>
    <div class="w-full p-5">
      <div class="table w-full">
        <div
          class="tags-cloud py-5 -mx-5 max-w-max sm:max-w-xl md:max-w-2xl lgg:max-w-full lg:mx-0"
          v-if="tagsCloud.length > 0"
        >
          <div id="chart" class="mb-5 brand_treemap">
            <apexchart
              type="treemap"
              height="350"
              :options="cloudChartOptions"
              :series="cloudSeries"
            ></apexchart>
          </div>
          <Divider />
        </div>
        <div class="text-right mb-4">
          <Button
            icon="pi pi-external-link me-2"
            label="تصدير"
            class="font-taj font-semibold bg-success ignore-element-class"
            @click="exportCSV($event)"
          />
        </div>
        <DataTable
          :value="tags"
          paginator
          :rows="10"
          stripedRows
          :rowsPerPageOptions="[5, 10, 20, 50]"
          tableStyle="min-width: 50rem"
          class="text-right font-taj"
          dir="rtl"
          ref="dt"
        >
          <Column
            class="text-right font-taj"
            field="name"
            header="اسم المستخدم"
            style="width: 65%"
            >0</Column
          >
          <Column
            field="count"
            header=" مرات التكرار "
            style="width: 20%"
            class="text-right font-taj"
          ></Column>
          <Column
            field="url"
            header="الرابط"
            style="width: 5%"
            class="text-right font-taj px-2 py-2"
          >
            <template #body="{ data }">
              <a
                :href="data.url"
                target="_blank"
                class="bg-white rounded-md p-1 flex items-center"
              >
                <font-awesome-icon
                  icon="fa-solid fa-arrow-up-right-from-square"
                  class="text-danger-800 text-xl"
                />
              </a>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Divider from "primevue/divider";
export default {
  name: "TagsChart",
  components: {
    DataTable,
    Column,
    Button,
    Divider,
  },
  props: {
    title: {
      type: String,
      default: "العنوان",
    },
    icon: {
      type: String,
      default: "fa-solid fa-globe",
    },
    color: {
      type: String,
      default: "bg-primary text-white",
    },
    tags: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const cloudSeries = ref([
      {
        data: [],
      },
    ]);
    const cloudChartOptions = ref({
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
      },
      title: {
        text: " الخريطة الهيكلية الموزعة لأكثر الكلمات المفتاحية ظهوراً",
        align: "center",
        offsetY: -7,
        style: {
          fontFamily: "Tajawal",
          fontSize: "18px",
        },
      },
      colors: [
        "#3B82F6", // Blue
        "#7C3AED", // Purple
        "#10B981", // Green
        "#EF4444", // Red
        "#F59E0B", // Yellow
        "#6366F1", // Indigo
        "#EC4899", // Pink
        "#14B8A6", // Teal
        "#F97316", // Orange
        "#22D3EE", // Cyan
        "#F43F5E", // Rose
        "#8B5CF6", // Violet
      ],
      dataLabels: {
        style: {
          fontSize: "16px", // Adjust the font size here
          fontFamily: "Tajawal",
        },
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
    });
    const dt = ref(null);
    const exportCSV = () => {
      if (dt.value) {
        dt.value.exportCSV();
      } else {
        console.error("DataTable reference is not yet available.");
      }
    };

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };
    const tagsCloud = computed(() => {
      /* eslint-disable */
      const newTags = props.tags.map(({ url, name: x, count: y }) => ({
        x,
        y,
      }));
      /* eslint-enable */
      const tags = newTags.slice(0, 25);
      return shuffleArray(tags);
    });

    function updateOptions() {
      cloudSeries.value[0].data = tagsCloud;
    }

    onMounted(() => {
      updateOptions();
    });
    watch(props, () => {
      updateOptions();
    });

    return { tagsCloud, dt, exportCSV, cloudSeries, cloudChartOptions };
  },
};
</script>
