<template>
  <div class="post-image">
    <img
      class="w-full"
      :src="encodeURI(postImage)"
      :alt="postTitle || postText"
      v-if="postImage"
      @error="handleImageError"
    />
  </div>
</template>

<script>
import placeholderImage from "@/assets/images/placeholder.png";
export default {
  name: "PostImage",
  props: {
    postImage: String,
    postTitle: String,
    postText: String,
  },

  setup() {
    function handleImageError(event) {
      // Handle the error when the image fails to load
      // For example, you can set a placeholder image or hide the image
      // event.target.style.display = "none"; // Hide the image
      // Or you can set a placeholder image
      event.target.src = placeholderImage; // Set a placeholder image
    }
    return { handleImageError };
  },
};
</script>
