<template>
  <div
    class="relative w-full min-h-96 mb-5 group rounded-lg overflow-hidden shadow-lg ring-1 ring-gray-200"
  >
    <div :class="['title p-3 mb-3', color]">
      <h2
        class="text-lg font-semibold flex flex-row justify-start items-center"
      >
        <font-awesome-icon :icon="icon" class="me-2 text-xl" />
        {{ title }}
      </h2>
    </div>
    <div id="chart" class="w-full p-5">
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
        class="text-left capitalize"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
export default {
  name: "SocialPostsChart",
  props: {
    title: {
      type: String,
      default: "العنوان",
    },
    icon: {
      type: String,
      default: "fa-solid fa-globe",
    },
    color: {
      type: String,
      default: "bg-primary text-white",
    },
    socialSeries: {
      type: Array,
      required: true,
    },
    socialCategories: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const series = ref([
      {
        data: [0], // reference prop value
      },
    ]);

    // Define chart options using ref
    const chartOptions = ref({
      chart: {
        type: "bar",
        height: 350,
        width: "100%",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barWidth: "1000px",
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
      },
      grid: {
        padding: {
          right: 100,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      yaxis: {
        reversed: true,
        axisTicks: {
          show: true,
        },
        labels: {
          style: {
            fontSize: "15px", // Adjust font size if needed
            align: "left", // Add margin-top to create space between labels and bars
            textTransform: "capitalize",
          },
        },
      },
    });

    function updateOptions() {
      series.value[0].data = props.socialSeries;
      chartOptions.value.xaxis.categories = props.socialCategories;
    }

    onMounted(() => {
      updateOptions();
    });
    watch(props, () => {
      updateOptions();
    });
    return { series, chartOptions, updateOptions };
  },
};
</script>

[{ "name": "Posts","data": [ 20, 40, 80] }]
