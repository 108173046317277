import moment from "moment";
/**
Retrieves custom options (translation, icon, color) based on the provided key.
@param {string | Array<string>} key - The key(s) to retrieve the custom options for.
@param {string} property - The property of the custom option to retrieve (e.g., 'translation', 'icon', 'color').
@returns {string | Array<string> | undefined} - The custom option value(s) corresponding to the provided key(s), or undefined if not found.
*/

function getCustomOption(key, property, getOptions = false) {
  const options = {
    neutral: {
      translation: "طبيعي",
      icon: "fa-regular fa-face-meh",
      color: "text-primary",
      bg: "bg-secondary text-white",
    },
    positive: {
      translation: "إيجابي",
      icon: "fa-regular fa-face-smile",
      color: "text-success",
      bg: "bg-success text-white",
    },
    negative: {
      translation: "سلبي",
      icon: "fa-regular fa-face-angry",
      color: "text-danger",
      bg: "bg-danger text-white",
    },
    notes: {
      translation: "ملاحظات",
      icon: "fa-regular fa-note-sticky",
      color: "bg-success text-white",
      class: "text-success",
    },
    comments: {
      translation: "تعليقات",
      icon: "fa-regular fa-comment",
      color: "bg-primary text-white",
      class: "text-primary",
    },
    views: {
      translation: "مشاهدة",
      icon: "fa-regular fa-eye",
      color: "bg-warning text-white",
      class: "text-warning",
    },
    likes: {
      translation: "اعجابات",
      icon: "fa-solid fa-heart",
      color: "bg-danger text-white",
      class: "text-danger",
    },
    reposts: {
      translation: "اعادة نشر",
      icon: "fa-solid fa-repeat",
      color: "bg-stone-400 text-white",
      class: "text-success",
    },
    shares: {
      translation: "مشاركات",
      icon: "fa-solid fa-share-nodes",
      color: "bg-cyan-400 text-white",
      class: "text-cyan-400",
    },
    retweets: {
      translation: "اعادة تغريد",
      icon: "fa-solid fa-retweet",
      color: "bg-purple-700 text-white",
      class: "text-purple-700",
    },
    followers: { translation: "المتابعين", icon: "fa-solid fa-users" },
    Sunday: { translation: "الأحد" },
    Monday: { translation: "الاثنين" },
    Tuesday: { translation: "الثلاثاء" },
    Wednesday: { translation: "الأربعاء" },
    Thursday: { translation: "الخميس" },
    Friday: { translation: "الجمعة" },
    Saturday: { translation: "السبت" },
    video: { translation: "فيديو", icon: "fa-solid fa-video" },
    photo: { translation: "صورة", icon: "fa-regular fa-image" },
    status: { translation: "حالة", icon: "fa-regular fa-pen-to-square" },
    link: { translation: "رابط", icon: "fa-solid fa-link" },
  };
  if (Array.isArray(key)) {
    // If the key is an array, map each item to its corresponding custom option value
    return key.map((item) => (options[item] ? options[item][property] : item));
  } else if (getOptions) {
    return options;
  } else {
    // If the key is a single value, return its corresponding custom option value
    return options[key] ? options[key][property] : key;
  }
}
/**
 * Returns an object containing initial values for posts data.
 * Each property represents a social media platform with its respective data structure.
 * @returns {Object} Initial posts data
 */
function getPostsInitializer() {
  const postsInitializer = {
    webPosts: {
      data: [],
      loading: false,
      title: "web",
      icon: "fa-solid fa-globe",
      options: {
        name: "web-tab",
        color: "text-danger",
      },
    },
    youtubePosts: {
      data: [],
      loading: false,
      title: "youtube",
      icon: "fa-brands fa-youtube",
      options: {
        name: "youtube-tab",
        color: "#FF0000",
      },
    },
    tumblrPosts: {
      data: [],
      loading: false,
      title: "tumblr",
      icon: "fa-brands fa-square-tumblr",
      options: {
        name: "tumblr-tab",
        color: "#001833",
      },
    },
    // redditPosts: {
    //   data: [],
    //   loading: false,
    //   title: "reddit",
    //   icon: "fa-brands fa-reddit-alien",
    //   options: {
    //     name: "reddit-tab",
    //     color: "#f74300",
    //   },
    // },
    dailymotionPosts: {
      data: [],
      loading: false,
      title: "dailymotion",
      icon: "fa-brands fa-dailymotion",
      options: {
        name: "dailymotion-tab",
        color: "#00c1ee",
      },
    },
    vimeoPosts: {
      data: [],
      loading: false,
      title: "vimeo",
      icon: "fa-brands fa-vimeo",
      options: {
        name: "vimeo-tab",
        color: "#3fabd4",
      },
    },
    vkontaktePosts: {
      data: [],
      loading: false,
      title: "VK",
      icon: "fa-brands fa-vk",
      options: {
        name: "vk-tab",
        color: "#0073f7",
      },
    },
    flickrPosts: {
      data: [],
      loading: false,
      title: "flickr",
      icon: "fa-brands fa-flickr",
      options: {
        name: "flickr-tab",
        color: "#f70084",
      },
    },
    facebookPosts: {
      data: [],
      loading: false,
      title: "facebook",
      icon: "fa-brands fa-facebook",
      requestId: null,
      options: {
        name: "facebook-tab",
        color: "#4267B2",
      },
    },
    twitterPosts: {
      data: [],
      loading: false,
      title: "twitter",
      icon: "fa-brands fa-twitter",
      requestId: null,
      options: {
        name: "twitter-tab",
        color: "#1DA1F2",
      },
    },
    instagramPosts: {
      data: [],
      loading: false,
      title: "instagram",
      icon: "fa-brands fa-instagram",
      requestId: null,
      options: {
        name: "instagram-tab",
        color: "#C13584",
      },
    },
    linkedinPosts: {
      data: [],
      loading: false,
      title: "linkedin",
      icon: "fa-brands fa-linkedin",
      requestId: null,
      options: {
        name: "linkedin-tab",
        color: "#007ab5",
      },
    },
  };
  return postsInitializer;
}

/**
 * Function to check and return the appropriate icon name based on the network name.
 * @param {string} name - The name of the network.
 * @returns {string} - The corresponding icon name.
 */
function checkIcon(name) {
  // Check if the network name is "web"
  if (name === "web") {
    return "internet-explorer"; // Return the icon name for web
  }
  // Check if the network name is "vkontakte"
  else if (name === "vkontakte") {
    return "vk"; // Return the icon name for vkontakte
  }
  // If network name is neither "web" nor "vkontakte"
  else {
    return name; // Return the network name itself as icon name
  }
}
/**
 * Returns an array containing the initial container names.
 * Each element represents a social media platform container.
 * @returns {string[]} Initial container names
 */
function getContainersInitializer() {
  const containers = [
    "webPosts",
    "youtubePosts",
    "tumblrPosts",
    // "redditPosts",
    "dailymotionPosts",
    "vimeoPosts",
    "vkontaktePosts",
    "flickrPosts",
    "facebookPosts",
    "twitterPosts",
    "instagramPosts",
    "linkedinPosts",
  ];
  return containers;
}

/**
 * Retrieves a list of available networks.
 * @param {boolean} isPremium - Indicates whether to include premium networks.
 * @returns {string[]} - An array containing the names of the available networks.
 */
function getNetworksList(isPremium = false) {
  const networks = [
    "web",
    "youtube",
    "tumblr",
    // "reddit",
    "dailymotion",
    "vimeo",
    "vkontakte",
    "flickr",
  ];
  const premiumNetworks = ["facebook", "twitter", "linkedin", "instagram"];
  if (isPremium) {
    return premiumNetworks;
  } else {
    return networks;
  }
}

/**
 * Formats a given date and time string into a human-readable format.
 * @param {string} dateTime - The date and time string to format (in "YYYY-MM-DD HH:mm:ss Z" format).
 * @returns {string} The formatted date and time string (e.g., "Monday 1st January 2022 at 12:00 PM").
 */
function formatDateTime(dateTime, format = null) {
  // Parse the date string as a UTC date with the specified format
  const utcDate = moment.utc(dateTime, "YYYY-MM-DD HH:mm:ss Z");
  // Format the date using your desired format

  if (format) {
    return utcDate.format(format);
  }
  return utcDate.format("dddd Do MMMM YYYY في h:mm a");
}

/**
 * Retrieves stored posts from the local storage and updates the 'data' property of the provided 'posts' object for each container.
 * @param {Object} posts - The object containing posts data for various containers.
 */
function getStoredPosts(posts) {
  const containers = getContainersInitializer();
  // Loop through the containers
  containers.forEach((container) => {
    const storedPosts = localStorage.getItem(container);
    if (storedPosts && storedPosts !== undefined && storedPosts !== null) {
      // Check if storedPosts is not null or undefined
      try {
        // Parse the stored posts JSON and update the 'data' property of the corresponding container
        posts[container].data = JSON.parse(storedPosts);
      } catch (error) {
        console.error(`Error parsing stored posts for ${container}:`, error);
      }
    } else {
      console.warn(`No stored posts found for ${container}`);
    }
  });
}

/**
 * Retrieves an item from local storage and assigns it to the provided variable.
 * @param {String} item - The name of the item to retrieve from local storage.
 * @param {Object} target - The target object where the retrieved item will be assigned.
 */
function retrieveItemFromLocalStorage(item, target) {
  const savedItem = localStorage.getItem(item);
  if (savedItem) {
    target[item] = savedItem;
  }
  return null;
}

/**
 * Sets an item in the local storage with the provided key and value.
 * @param {String} key - The key under which to store the value in local storage.
 * @param {String} value - The value to store in local storage.
 */
function setItemInLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

/**
 * Function to clear specific items from the local storage and reset the 'posts' data.
 * It removes the search query item, items corresponding to each container obtained from 'getContainersInitializer()',
 * and the 'posts' item. After removing these items, it resets the 'posts' data using 'getPostsInitializer()' function.
 */
function clearLocalStorage(item, target) {
  localStorage.removeItem("searchQuery");
  const containers = getContainersInitializer();
  containers.forEach((container) => {
    localStorage.removeItem(container);
  });
  localStorage.removeItem(item);
  target[item] = getPostsInitializer();
}

/**
 * Retrieves stored posts from localStorage for a specified container and updates the posts object.
 * @param {string} container - The name of the container to retrieve stored posts for.
 * @param {Ref} posts - A Vue ref representing the posts object.
 */
function retrieveStoredSingleNetworkPost(container, posts) {
  // Retrieve stored posts from localStorage for the specified container
  const storedPosts = localStorage.getItem(container);

  // Check if storedPosts is not null, undefined, or empty
  if (storedPosts && storedPosts !== undefined && storedPosts !== null) {
    try {
      // Attempt to parse storedPosts as JSON
      const parsedPosts = JSON.parse(storedPosts);

      // Check if the specified container is not defined in the posts object
      if (!posts.value[container]) {
        // If not defined, create a new entry in the posts object with parsedPosts as data
        posts.value[container] = { data: parsedPosts };
      } else if (!posts.value[container].data) {
        // If data property is not defined in the specified container, set it to parsedPosts
        posts.value[container].data = parsedPosts;
      }
    } catch (error) {
      // Handle parsing errors
      console.error(`Error parsing stored posts for ${container}:`, error);
    }
  } else {
    // Log a warning if no stored posts are found for the specified container
    console.warn(`No stored posts found for ${container}`);
  }
}

/**
 * Retrieves the top 20 popular posts from the given data.
 * Calculates the total popularity of each post across different networks,
 * sorts them based on their popularity, and returns the top 20 posts with their details.
 *
 * @param {Object} data - An object containing data for different networks, each network containing posts.
 * @returns {Array<Object>} - An array of objects representing the top 20 popular posts with their details.
 */
function getTopPosts(postsData) {
  // Initialize an array to store the total popularity of each post
  let totalPopularity = [];

  // Iterate over each network
  for (const network in postsData) {
    // Check if the property belongs to the object itself (not inherited)
    if (Object.prototype.hasOwnProperty.call(postsData, network)) {
      // Get the posts postsData for the current network
      const posts = postsData[network].data;

      // Check if posts is an array
      if (posts && Array.isArray(posts)) {
        // Iterate over each post
        posts.forEach((post) => {
          // Check if the post has popularity data
          if (post.popularity) {
            // Calculate the total popularity count for the post
            const postPopularityCount = post.popularity.reduce(
              (total, popularityItem) => total + popularityItem.count,
              0
            );
            // Push an object containing post ID, popularity count, and network to the totalPopularity array
            totalPopularity.push({
              postId: post.postid,
              popularity: postPopularityCount,
              network: network,
            });
          }
        });
      }
    }
  }

  // Sort the totalPopularity array based on popularity count in descending order
  totalPopularity.sort((a, b) => b.popularity - a.popularity);

  // Get the top 20 popular posts
  const topPosts = totalPopularity.slice(0, 25);

  // Retrieve the full post details for the top 20 popular posts
  const popularPosts = topPosts.map(({ postId, popularity, network }) => {
    // Find the post with the given postId in the corresponding network's data
    const post = postsData[network].data.find((post) => post.postid === postId);
    // Add the totalpopularity property to the post object
    return { ...post, totalpopularity: popularity };
  });

  // Return the top 20 popular posts with their details
  return popularPosts;
}

/**
 * Shortens a large number by converting it into a more readable format with Arabic suffixes.
 * For example, 1000 will be represented as "1 ألف".
 * @param {number} number - The number to be shortened.
 * @returns {string|number} - The shortened number with Arabic suffix, or the original number if it's less than or equal to 1000.
 */
function shortenNumber(number) {
  // Define the Arabic suffixes for thousands, millions, billions, and so on
  const suffixes = ["", "ألف", "مليون", "مليار", "تريليون"];

  // Determine the suffix index based on the length of the number
  const suffixIndex = Math.floor((number.toString().length - 1) / 3);

  // Shorten the number to keep up to 3 digits and append the appropriate suffix
  const shortNumber =
    (number / Math.pow(1000, suffixIndex)).toFixed(1) +
    " " +
    suffixes[suffixIndex];

  // If the number is less than or equal to 1000, return the original number
  if (number <= 1000) {
    return number;
  }

  // Return the shortened number with Arabic suffix
  return shortNumber;
}

/**
 * Retrieves an excerpt from a given text containing the specified number of words.
 * @param {string} text - The text from which to extract the excerpt.
 * @param {number} count - The number of words to include in the excerpt.
 * @returns {string} - The excerpt containing the specified number of words.
 */
function getExcerpt(text, count) {
  // Split the text into an array of words
  const words = text.trim().split(/\s+/);

  // Return the specified number of words joined back into a string
  return words.slice(0, count).join(" ");
}

/**
 * Checks if all loading properties in the posts object are false.
 * @param {object} posts - The posts object containing loading properties to check.
 * @returns {boolean} Returns true if all loading properties are false, otherwise returns false.
 */
function isSearchFinished(posts) {
  // Iterate over each key in the posts object
  for (const key in posts) {
    // Check if the loading property of the current post is true
    if (posts[key].loading) {
      // If any loading property is true, return false
      return false;
    }
  }
  // If all loading properties are false, return true
  return true;
}
export {
  getCustomOption,
  checkIcon,
  getPostsInitializer,
  getContainersInitializer,
  formatDateTime,
  getStoredPosts,
  retrieveItemFromLocalStorage,
  setItemInLocalStorage,
  clearLocalStorage,
  getNetworksList,
  retrieveStoredSingleNetworkPost,
  getTopPosts,
  shortenNumber,
  getExcerpt,
  isSearchFinished,
};
