<template>
  <div class="flex bg-gray-50 font-taj">
    <div class="container mx-auto">
      <div class="title">
        <h1 class="text-3xl font-bold">الاستهلاك</h1>
      </div>
      <div class="grid grid-cols-3 gap-3">
        <div class="premium text-right">
          <!-- radialBar chart componenet -->
          <apexchart
            type="radialBar"
            height="350"
            class="text-right font-taj"
            :options="premiumOptions"
            :series="premiumSeries"
          ></apexchart>
          <div
            class="premium_numbers text-right capitalize"
            v-if="accounts.length > 0"
          >
            <p class="text-md">
              <span class="inline-block w-[10px] h-[10px] bg-primary"></span>
              الحد المسموح :
              {{ formatNumber(accounts[0].premium.limit) }}
            </p>
            <p class="text-md">
              <span class="inline-block w-[10px] h-[10px] bg-success"></span>
              المستهلك: {{ formatNumber(accounts[0].premium.posts_count) }}
            </p>
            <p class="text-md">
              <span class="inline-block w-[10px] h-[10px] bg-danger"></span>
              المتبقي:
              {{ accounts[0].premium.limit - accounts[0].premium.posts_count }}
            </p>
          </div>
        </div>
        <div class="search text-right">
          <apexchart
            type="radialBar"
            height="350"
            class="text-right"
            :options="searchOptions"
            :series="searchSeries"
          ></apexchart>
          <div
            class="searchm_numbers text-right capitalize"
            v-if="accounts.length > 0"
          >
            <p class="text-md">
              <span class="inline-block w-[10px] h-[10px] bg-primary"></span>
              الحد المسموح :
              {{ accounts[0].search.limit }}
            </p>
            <p class="text-md">
              <span class="inline-block w-[10px] h-[10px] bg-success"></span>
              المستهلك: {{ accounts[0].search.requests_count }}
            </p>
            <p class="text-md">
              <span class="inline-block w-[10px] h-[10px] bg-danger"></span>
              المتبقي:
              {{ accounts[0].search.limit - accounts[0].search.requests_count }}
            </p>
          </div>
        </div>
      </div>

      <div class="subscription mt-10">
        <div class="card">
          <DataTable :value="dates" showGridlines tableStyle="min-width: 50rem">
            <Column
              field="code"
              header="الاشتراك"
              class="border text-right font-taj"
            ></Column>
            <Column
              field="name"
              header="تاريخ الانتهاء"
              class="border text-right font-taj"
            ></Column>
          </DataTable>
        </div>
      </div>
      <!-- <div class="text-xl text-left">
        <pre v-if="accounts.length > 0">
            {{ accounts[0] }}
          </pre
        >
      </div> -->
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
</script>
<script>
export default {
  name: "SettingsView",
  // fetch data on loading page
  mounted() {
    this.fetchAccounts();
  },
  methods: {
    fetchAccounts() {
      const identifier = "accounts"; // endpoint identifier [search, account, ...]
      const apiUrl = `api/proxy/${identifier}/`; // Proxy URL
      // use axios to request data
      axios
        .get(apiUrl)
        .then((response) => {
          this.accounts = response.data.accounts;
          // Once accounts are fetched, update the series with premium limit
          const premiumPercentage = this.calculatePercentage(
            this.accounts[0].premium.posts_count || 0,
            this.accounts[0].premium.limit || 0
          );
          this.premiumSeries = [premiumPercentage];

          const searchPercentage = this.calculatePercentage(
            this.accounts[0].search.requests_count || 0,
            this.accounts[0].search.limit || 0
          );
          this.searchSeries = [searchPercentage];

          // get dates from Api and push it to dates array
          this.dates.push(
            {
              code: "الاشتراك العادي",
              name: this.accounts[0].monitoring.period_end,
            },
            {
              code: "الاشتراك المميز",
              name: this.accounts[0].premium.period_end,
            }
          );
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    },
    calculatePercentage(partial, total) {
      let percentage;
      if (partial == 0) {
        percentage = 100;
      } else if (partial == total) {
        percentage = 0;
      } else {
        total = (partial / total) * 100;
        percentage = 100 - total;
      }
      const roundedPercentage = parseFloat(percentage.toFixed(2));
      return roundedPercentage;
    },
    formatNumber(number) {
      // Check if the number is greater than or equal to 1000
      if (Math.abs(number) >= 1000) {
        // Convert the number to a string and get the first character
        const firstDigit = String(number)[0];
        // Check if the number is greater than or equal to 100000
        if (Math.abs(number) >= 100000) {
          // If the number is greater than or equal to 100000, return the first digit followed by "00K"
          return firstDigit + "00K";
        } else {
          // Otherwise, return the first digit followed by "K"
          return firstDigit + "K";
        }
      } else {
        // If the number is less than 1000, return the number as is
        return String(number);
      }
    },
  },
  data() {
    return {
      accounts: [],
      premiumSeries: [0], // Initialize series
      searchSeries: [0],
      dates: [],
      premiumOptions: {
        chart: {
          height: 350,
          type: "radialBar",
          fontFamily: "Tajawal, sans-serif",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
          },
        },
        labels: ["الاشتراك المميز"],
      },
      searchOptions: {
        chart: {
          height: 350,
          type: "radialBar",
          fontFamily: "Tajawal, sans-serif",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
          },
        },
        labels: ["عمليات البحث الحالية"],
      },
    };
  },
};
</script>
