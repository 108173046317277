<template>
  <div
    class="relative w-full h-52 bg-cover bg-center group rounded-lg overflow-hidden shadow-lg transition duration-300 ease-in-out StatisticsViewCardBG"
  >
    <div
      :class="[
        'absolute inset-0 bg-opacity-75 transition duration-300 ease-in-out',
        classes,
      ]"
    ></div>
    <div class="relative w-full h-full px-4 sm:px-6 lg:px-4 flex items-center">
      <button
        @click="visible = true"
        class="bg-white absolute top-4 left-4 rounded-md p-1 flex items-center transition hover:bg-gray-100"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="text-blue-800 text-xl"
        />
      </button>
      <div>
        <div class="text-white text-lg flex items-center">
          <div class="bg-white rounded-md p-2 flex items-center me-5">
            <font-awesome-icon :icon="icon" :class="['text-4xl', color]" />
          </div>
          <div class="count">
            <h2 class="text-2xl font-semibold">{{ title }}</h2>
            <h3 class="text-white text-4xl font-bold">{{ method }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    maximizable
    modal
    :header="dialogTitle"
    :draggable="false"
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <template #header>
      <h2 class="px-5 py-4 font-taj text-2xl font-semibold">
        {{ dialogTitle }}
      </h2>
    </template>
    <div class="m-0 list" v-if="listType == 'list'">
      <DataTable
        :value="data"
        paginator
        :rows="10"
        stripedRows
        :rowsPerPageOptions="[5, 10, 20, 50]"
        tableStyle="min-width: 50rem"
        class="text-right font-taj"
        dir="rtl"
      >
        <Column
          field="count"
          header="الصورة"
          style="width: 1%"
          class="text-right font-taj px-2 py-2"
        >
          <template #body="{ data }">
            <Avatar
              :image="data.image"
              class="ml-2"
              size="large"
              shape="circle"
            />
          </template>
        </Column>
        <Column
          class="text-right font-taj"
          field="name"
          header="اسم المستخدم"
          style="width: 65%"
          >0</Column
        >
        <Column
          field="count"
          header="عدد مرات الاشارة "
          style="width: 20%"
          class="text-right font-taj"
        ></Column>
        <Column
          field="url"
          header="الرابط"
          style="width: 5%"
          class="text-right font-taj px-2 py-2"
        >
          <template #body="{ data }">
            <a
              :href="data.url"
              target="_blank"
              class="bg-white rounded-md p-1 flex items-center"
            >
              <font-awesome-icon
                icon="fa-solid fa-arrow-up-right-from-square"
                class="text-danger-800 text-xl"
              />
            </a>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="social" v-if="listType == 'social'">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div
          class="network font-taj relative w-full h-32 mb-5 flex flex-col justify-center items-center group rounded-lg overflow-hidden shadow-lg ring-1 ring-gray-200 transition duration-300 ease-in-out text-center"
          v-for="(network, index) in data"
          :key="index"
        >
          <font-awesome-icon
            :icon="`fa-brands fa-${checkIcon(network.name)}`"
            class="text-primary text-3xl my-2"
          />
          <h3 class="mb-2 text-md font-semibold capitalize">
            {{ network.name }}
          </h3>
          <p class="mb-2 text-2xl font-bold">{{ network.count }}</p>
        </div>
      </div>
    </div>
    <div class="feelings min-h-96 font-taj" v-if="listType == 'feelings'">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div
          class="sentiment py-24 relative w-full h-32 mb-5 flex flex-col justify-center items-center group rounded-lg overflow-hidden shadow-lg ring-1 ring-gray-200 transition duration-300 ease-in-out text-center"
          v-for="(sentiment, index) in data"
          :key="index"
        >
          <font-awesome-icon
            :icon="getCustomOption(sentiment.name, 'icon')"
            :class="['text-5xl my-2', getCustomOption(sentiment.name, 'color')]"
          />
          <h3 class="mb-2 text-lg font-semibold capitalize">
            {{ getCustomOption(sentiment.name, "translation") }}
          </h3>
          <p class="mb-2 text-2xl font-bold">{{ sentiment.count }}</p>
        </div>
      </div>
    </div>
    <div class="feelings min-h-96 font-taj" v-if="listType == 'popularity'">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div
          class="popularity flex flex-row-reverse justify-start items-center f p-0 relative w-full mb-5 group rounded-lg overflow-hidden shadow-lg ring-1 ring-gray-200 transition duration-300 ease-in-out text-center"
          v-for="(popularity, index) in data"
          :key="index"
        >
          <div class="icon h-full min-w-20">
            <font-awesome-icon
              :icon="getCustomOption(popularity.name, 'icon')"
              :class="[
                'text-5xl px-3 py-4 ms-5',
                getCustomOption(popularity.name, 'color'),
              ]"
            />
          </div>
          <div class="content flex flex-row items-center justify-center">
            <h3 class="me-2 text-lg font-semibold capitalize">
              {{ getCustomOption(popularity.name, "translation") }}
            </h3>
            <p class="text-2xl font-bold">{{ popularity.count }}</p>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ref } from "vue";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Avatar from "primevue/avatar";
import { getCustomOption, checkIcon } from "@/views/dashboard/helpers/helpers";
export default {
  name: "CounterCard",
  components: {
    Dialog,
    DataTable,
    Column,
    Avatar,
  },
  props: {
    title: String,
    data: Object,
    dialogTitle: String,
    listType: {
      type: String,
      default: "list",
    },
    color: {
      type: String,
      default: "text-blue-800",
    },
    icon: {
      type: String,
      default: "fa-regular fa-paste",
    },
    classes: String,
    method: {
      type: Number,
    },
  },
  setup() {
    const visible = ref(false);

    return { visible, getCustomOption, checkIcon };
  },
};
</script>
