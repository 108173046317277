<template>
  <!-- component -->
  <div
    class="w-auto min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-50 max-w-[170px] 2xl:max-w-[256px] z-50"
  >
    <div
      class="fixed flex flex-col top-0 right-0 w-auto lg:w-64 bg-gray-800 h-full z-10 max-w-[170px] 2xl:max-w-[256px]"
    >
      <div class="overflow-y-auto overflow-x-hidden flex-grow">
        <ul class="flex flex-col py-4 space-y-1">
          <li>
            <router-link
              to="/dashboard"
              class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-700 text-gray-200 hover:text-gray-100 border-l-4 border-transparent hover:border-indigo-500 pr-6"
              :class="{
                'bg-gray-700 text-gray-100 border-indigo-500':
                  $route.path === '/dashboard',
              }"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <!-- <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg> -->
                <svg
                  class="fill-stroke"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 4H15C14.4477 4 14 4.44772 14 5V9C14 9.55228 14.4477 10 15 10H19C19.5523 10 20 9.55228 20 9V5C20 4.44772 19.5523 4 19 4Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="hidden lg:block ml-2 text-sm tracking-wide truncate"
                >لوحة التحكم</span
              >
            </router-link>
          </li>
          <li>
            <router-link
              to="/statistics"
              class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-700 text-gray-200 hover:text-gray-100 border-l-4 border-transparent hover:border-indigo-500 pr-6"
              :class="{
                'bg-gray-700 text-gray-100 border-indigo-500':
                  $route.path === '/statistics',
              }"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <svg
                  class="fill-current h-5 w-5 text-gray-300 mx-auto hover:text-green-500"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.775 8C22.9242 8.65461 23 9.32542 23 10H14V1C14.6746 1 15.3454 1.07584 16 1.22504C16.4923 1.33724 16.9754 1.49094 17.4442 1.68508C18.5361 2.13738 19.5282 2.80031 20.364 3.63604C21.1997 4.47177 21.8626 5.46392 22.3149 6.55585C22.5091 7.02455 22.6628 7.5077 22.775 8ZM20.7082 8C20.6397 7.77018 20.5593 7.54361 20.4672 7.32122C20.1154 6.47194 19.5998 5.70026 18.9497 5.05025C18.2997 4.40024 17.5281 3.88463 16.6788 3.53284C16.4564 3.44073 16.2298 3.36031 16 3.2918V8H20.7082Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 14C1 9.02944 5.02944 5 10 5C10.6746 5 11.3454 5.07584 12 5.22504V12H18.775C18.9242 12.6546 19 13.3254 19 14C19 18.9706 14.9706 23 10 23C5.02944 23 1 18.9706 1 14ZM16.8035 14H10V7.19648C6.24252 7.19648 3.19648 10.2425 3.19648 14C3.19648 17.7575 6.24252 20.8035 10 20.8035C13.7575 20.8035 16.8035 17.7575 16.8035 14Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <span class="hidden lg:block ml-2 text-sm tracking-wide truncate"
                >الإحصائيات</span
              >
              <!-- <span
                class="px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-indigo-500 bg-indigo-50 rounded-full"
                >New</span
              > -->
            </router-link>
          </li>
          <li>
            <router-link
              to="/social-users"
              class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-700 text-gray-200 hover:text-gray-100 border-l-4 border-transparent hover:border-indigo-500 pr-6"
              :class="{
                'bg-gray-700 text-gray-100 border-indigo-500':
                  $route.path === '/social-users',
              }"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <svg
                  class="fill-stroke"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="hidden lg:block ml-2 text-sm tracking-wide truncate"
                >المستخدمين</span
              >
            </router-link>
          </li>
          <li>
            <router-link
              to="/social-posts"
              class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-700 text-gray-200 hover:text-gray-100 border-l-4 border-transparent hover:border-indigo-500 pr-6"
              :class="{
                'bg-gray-700 text-gray-100 border-indigo-500':
                  $route.path === '/social-posts',
              }"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  ></path>
                </svg>
              </span>
              <span class="hidden lg:block ml-2 text-sm tracking-wide truncate"
                >المنشورات</span
              >
            </router-link>
          </li>
          <li>
            <router-link
              to="/settings"
              class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-700 text-gray-200 hover:text-gray-100 border-l-4 border-transparent hover:border-indigo-500 pr-6"
              :class="{
                'bg-gray-700 text-gray-100 border-indigo-500':
                  $route.path === '/settings',
              }"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
              </span>
              <span class="hidden lg:block ml-2 text-sm tracking-wide truncate"
                >الاستهلاك</span
              >
            </router-link>
          </li>
          <li>
            <button
              @click="logout()"
              class="relative flex flex-row items-center w-full h-11 focus:outline-none hover:bg-gray-700 text-gray-200 hover:text-gray-100 border-l-4 border-transparent hover:border-indigo-500 pr-6"
            >
              <span class="inline-flex justify-center items-center ml-4">
                <svg
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  ></path>
                </svg>
              </span>
              <span class="hidden lg:block ml-2 text-sm tracking-wide truncate"
                >تسجيل الخروج</span
              >
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted, watch } from "vue";
export default {
  name: "NavigationView",
  methods: {
    logout() {
      axios
        .post("/api/v1/token/logout")
        .then((response) => {
          const token = response.data.auth_token;
          axios.defaults.headers.common["Authorization"] = "";
          localStorage.removeItem("token");
          this.$store.commit("removeToken", token);
          this.$router.push("/login");
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },
  },

  setup() {
    const status = ref(null);

    // Watch for changes in localStorage and update status accordingly
    watch(
      () => status.value,
      (newStatus) => {
        localStorage.setItem("counts", newStatus);
      }
    );

    // Alternatively, you can update status on component mount
    onMounted(() => {
      status.value = localStorage.getItem("counts");
    });

    return { status: status };
  },
};
</script>
