<template>
  <div class="user my-3" v-if="postUser">
    <div v-if="postUser" class="flex flex-row justify-between items-center">
      <a :href="postUser.url || '#'" class="hover:text-primary" target="_blank">
        <img
          v-if="postUser.image"
          :src="postUser.image"
          alt="{{ postUser.name }}"
          class="relative inline-block h-7 w-7 rounded-full object-cover object-center me-2"
        />
        <span class="font-semibold text-xs" v-if="!postUser.image"
          >بواسطة :
          <span v-if="!postUser.name">غير معروف</span>
        </span>
        <span v-if="postUser.name" class="font-semibold text-sm">
          {{ postUser.name }}</span
        >
      </a>
      <Button
        icon="pi pi-info-circle"
        severity="secondary"
        rounded
        aria-label="Favorite"
        v-if="postUser"
        @click="selectedUser(postUser)"
        class="w-[25px] h-[25px] text-xs"
      />
      <Dialog
        v-if="userToShow === postUser"
        :visible="visible"
        @update:visible="visible = $event"
        modal
        header="معلومات المستخدم"
        :style="{ width: '25rem' }"
        :draggable="false"
        :pt="{
          root: 'border-none',
          mask: {
            style: 'backdrop-filter: blur(.5px)',
          },
        }"
      >
        <template #header>
          <div
            class="inline-flex flex-row-reverse items-center justify-content-center gap-2"
          >
            <Avatar
              :image="userToShow.image"
              shape="circle"
              size="large"
              v-if="userToShow.image === postUser.image"
            />
            <span
              class="font-bold white-space-nowrap font-taj"
              v-if="userToShow.name === postUser.name"
              >{{ userToShow.name }}</span
            >
          </div>
        </template>
        <div
          class="flex flex-col items-center gap-3 mb-3 border-t border-b py-4 font-taj text-base text-right"
          v-if="postUser.description"
        >
          <p>
            {{ userToShow.description }}
          </p>
          <div
            class="influence flex flex-row-reverse items-center justify-start"
            v-if="postUser.influence"
          >
            <span
              class="flex flex-row-reverse items-center"
              v-if="postUser.influence.name"
            >
              <font-awesome-icon
                :icon="getCustomOption(userToShow.influence.name, 'icon')"
                class="ms-2 text-primary"
              />
              عدد
              {{ getCustomOption(userToShow.influence.name, "translation") }}
            </span>
            :
            <span v-if="postUser.influence.count">
              {{ userToShow.influence.count }}
            </span>
          </div>
        </div>
        <template #footer>
          <Button
            label="اغلاق"
            text
            severity="secondary"
            @click="visible = false"
            class="bg-danger-100 text-danger font-taj font-semibold text-sm"
          />
          <a
            :href="userToShow.url"
            target="_blank"
            rel="noopener"
            v-if="postUser.url"
          >
            <Button
              label="رابط المستخدم"
              outlined
              severity="secondary"
              @click="visible = false"
              class="bg-primary-100 text-primary font-taj font-semibold text-sm"
            />
          </a>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { getCustomOption } from "@/views/dashboard/helpers/helpers";
import Dialog from "primevue/dialog";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
export default {
  name: "PostUser",
  components: {
    Dialog,
    Avatar,
    Button,
  },
  props: {
    postUser: Object,
    selectedUser: {
      type: Function,
      required: true,
    },
    userToShow: Object,
    visiblity: Boolean,
  },

  setup(props) {
    const visible = ref(false);

    onMounted(() => {
      visible.value = props.visiblity;
    });

    watch(
      () => props.visiblity,
      (newValue) => {
        visible.value = newValue;
      }
    );

    return { visible, getCustomOption };
  },
};
</script>
