<template>
  <button
    class="px-4 py-2 bg-danger text-white text-sm font-semibold font-taj rounded-md"
    @click="exportAsPdf"
  >
    تحميل الإحصائيات
  </button>
  <div id="pdf-content" class="min-h-screen py-5">
    <!-- {{ PostsByDay }} -->
    <div class="container">
      <div id="firstPage" class="">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <CounterCard
            title=" المقالات"
            icon="fa-regular fa-paste"
            classes="bg-blue-900"
            color="text-blue-900"
            dialogTitle="المصادر"
            listType="social"
            :method="postCounts"
            :data="networkList"
          />
          <CounterCard
            title=" المستخدمين"
            icon="fa-regular fa-user"
            classes="bg-yellow-600"
            color="text-yellow-600"
            dialogTitle="المستخدمين"
            listType="list"
            :method="users"
            :data="usersList"
          />
          <CounterCard
            title=" المشاعر"
            icon="fa-regular fa-face-smile"
            classes="bg-pink-900"
            color="text-pink-900"
            dialogTitle="المشاعر"
            listType="feelings"
            :method="sentiment"
            :data="sentimentList"
          />
          <CounterCard
            title="التفاعل"
            icon="fa-regular fa-face-smile"
            classes="bg-purple-900"
            color="text-pink-900"
            dialogTitle="التفاعل"
            listType="popularity"
            :method="popularity"
            :data="popularityList"
          />
        </div>
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-4 mt-10">
          <SocialPostsChart
            title="المقالات مقابل المنصات"
            icon="fa-regular fa-bookmark"
            color="bg-primary text-white"
            :socialSeries="listOfNetworksCount"
            :socialCategories="listOfNetworksNames"
          />
          <SentimentChart
            title="المشاعر"
            icon="fa-solid fa-chart-pie"
            color="bg-pink-700 text-white"
            :sentimentSeries="listOfSentimentCount"
            :sentimentCategories="listOfSentimentNames"
          />
          <PostsByDayChart
            title="المقالات في الايام"
            icon="fa-regular fa-calendar"
            color="bg-green-900 text-white"
            :days="PostsByDay"
            :daysCount="PostsByDayCount"
          />
          <PostsByHourChart
            title="المقالات في الساعات"
            icon="fa-regular fa-calendar"
            color="bg-purple-900 text-white"
            :days="PostsByHoursKeys"
            :daysCount="PostsByHoursValues"
          />
        </div>
      </div>
      <div id="secondPage">
        <TagsChart
          title="الكلمات المفتاحية"
          icon="fa-solid fa-tags"
          color="bg-yellow-600 text-white"
          :tags="listOfTags"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CounterCard from "@/components/statistics/CounterCard.vue";
import SocialPostsChart from "@/components/statistics/SocialPostsChart.vue";
import SentimentChart from "@/components/statistics/SentimentChart.vue";
import TagsChart from "@/components/statistics/TagsChart.vue";
import PostsByDayChart from "@/components/statistics/PostsByDayChart.vue";
import PostsByHourChart from "@/components/statistics/PostsByHourChart.vue";
import {
  postCounts,
  retrieveStoredPosts,
  extractData,
  extractDataOnlyCountsOrNames,
  calculateSum,
  getItemCounts,
  extractKeysAndValues,
  countPostsByTwoHourInterval,
  countPostsByDay,
} from "./helpers/StatisticsView";
export default {
  name: "StatisticsView",
  components: {
    CounterCard,
    SocialPostsChart,
    SentimentChart,
    TagsChart,
    PostsByDayChart,
    PostsByHourChart,
  },
  setup() {
    const storedPosts = ref([]);
    const posts = ref({});
    // array of containers that stored in the data in dashboard view
    const containers = ref([
      "webPosts",
      "youtubePosts",
      "tumblrPosts",
      // "redditPosts",
      "dailymotionPosts",
      "vimeoPosts",
      "vkontaktePosts",
      "flickrPosts",
      "facebookPosts",
      "twitterPosts",
      "instagramPosts",
      "linkedinPosts",
    ]);

    function exportAsPdf() {
      const element = document.getElementById("pdf-content"); // ID of the component's container element
      const options = {
        scale: 2, // Adjust scale for better quality
        useCORS: true, // Enable cross-origin request
        scrollX: 30, // Set scroll position
        scrollY: -window.scrollY, // Set scroll position
        windowWidth: document.documentElement.offsetWidth, // Set window width
        windowHeight: document.documentElement.offsetHeight, // Set window height
        width: element.offsetWidth, // Set width of the area to capture
        height: element.offsetHeight, // Set height of the area to capture
        x: 0, // Set x position of the area to capture
        y: 0, // Set y position of the area to capture
        logging: true, // Enable logging to console
        ignoreElements: (element) => {
          // Ignore elements you don't want to capture (optional)
          return element.classList.contains("ignore-element-class");
        },
      };
      html2canvas(element, options).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save("report.pdf");
      });
    }
    // the function imported from StatisticsView.js
    const computedPostCounts = postCounts(posts);
    const users = computed(() => {
      return extractData(posts, "user");
    });
    const networkList = computed(() => {
      return extractData(posts, "network", false, true);
    });
    const usersList = computed(() => {
      return extractData(posts, "user", false, true);
    });
    const sentiment = computed(() => {
      return extractData(posts, "sentiment", false);
    });
    const sentimentList = computed(() => {
      return extractData(posts, "sentiment", false, true);
    });
    const popularity = computed(() => {
      return calculateSum(posts, "popularity");
    });
    const popularityList = computed(() => {
      return calculateSum(posts, "popularity", false);
    });

    const listOfNetworksCount = computed(() => {
      return extractDataOnlyCountsOrNames(posts, "network");
    });
    const listOfNetworksNames = computed(() => {
      return extractDataOnlyCountsOrNames(posts, "network", false);
    });

    const listOfSentimentCount = computed(() => {
      return extractDataOnlyCountsOrNames(posts, "sentiment");
    });
    const listOfSentimentNames = computed(() => {
      return extractDataOnlyCountsOrNames(posts, "sentiment", false);
    });
    const listOfTags = computed(() => {
      return getItemCounts(posts, "tags");
    });
    const PostsByDay = computed(() => {
      return extractKeysAndValues(posts, countPostsByDay);
    });
    const PostsByDayCount = computed(() => {
      return extractKeysAndValues(posts, countPostsByDay, false);
    });
    const PostsByHoursKeys = computed(() => {
      return extractKeysAndValues(posts, countPostsByTwoHourInterval);
    });
    const PostsByHoursValues = computed(() => {
      return extractKeysAndValues(posts, countPostsByTwoHourInterval, false);
    });
    onMounted(() => {
      retrieveStoredPosts(containers.value, posts);
    });
    // to add method you do that
    // function functionName() {}
    return {
      storedPosts,
      posts,
      postCounts: computedPostCounts,
      retrieveStoredPosts,
      users,
      sentiment,
      usersList,
      sentimentList,
      networkList,
      popularity,
      popularityList,
      listOfNetworksCount,
      listOfNetworksNames,
      listOfSentimentCount,
      listOfSentimentNames,
      listOfTags,
      PostsByDay,
      PostsByDayCount,
      PostsByHoursKeys,
      PostsByHoursValues,
      exportAsPdf,
    };
  },
};
</script>
