/**
 * Function to retrieve the top users based on their appearance count in the posts.
 * @param {Object} posts - The posts object containing data for different networks.
 * @returns {Array} - An array containing the top 20 users based on appearance count.
 */
function getTopUsers(posts) {
  // Object to store user appearance data
  const userAppearanceMap = {};

  // Iterate through each network in the posts object
  for (const network in posts) {
    if (Object.prototype.hasOwnProperty.call(posts, network)) {
      // Get the posts data for the current network
      const networkPosts = posts[network].data;

      // Iterate through each post in the network
      for (const post of networkPosts) {
        // Destructure user and network from the post object
        const { user, network } = post;

        // Check if user and user name are defined before accessing properties
        if (user && user.name) {
          const userName = user.name;
          const influence = user.influence;
          const url = user.url;

          // If the user is already in the map, increment their appearance count
          if (
            Object.prototype.hasOwnProperty.call(userAppearanceMap, userName)
          ) {
            userAppearanceMap[userName].count++;
          } else {
            // Otherwise, add the user to the map with initial appearance count
            userAppearanceMap[userName] = {
              name: userName,
              count: 1,
              url: url,
              network: network,
              influence: influence ? influence : [], // Include influence if available
            };
          }
        }
      }
    }
  }

  // Convert the userAppearanceMap object to an array
  const userAppearanceArray = Object.values(userAppearanceMap);

  // Sort the array based on the count property in descending order
  userAppearanceArray.sort((a, b) => b.count - a.count);

  // Extract the top 20 users from the sorted array
  const topUsers = userAppearanceArray.slice(0, 20);

  return topUsers;
}

export { getTopUsers };
