<template>
  <div class="my-3" v-if="postTags">
    <a
      v-for="(tag, i) in postTags"
      :key="i"
      :href="tag.url"
      target="_blank"
      class="inline-block bg-gray-200 hover:bg-gray-300 transition rounded-full px-3 py-1 text-sm font-normal text-gray-700 mr-2 mb-2"
      >#{{ tag.text }}</a
    >
  </div>
</template>

<script>
export default {
  name: "PostTags",
  props: {
    postTags: Object,
  },
};
</script>
