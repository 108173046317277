<template>
  <div v-if="topPosts">
    <div class="flex flex-row justify-between pt-4">
      <h1 class="text-2xl font-semibold">المنشورات الاكثر تفاعلا</h1>
      <Button
        icon="pi pi-external-link me-2"
        label="تصدير"
        class="font-taj font-semibold bg-success ignore-element-class mb-5"
        @click="exportCSV($event)"
      />
    </div>
    <DataTable
      :value="topPosts"
      tableStyle="min-width: 50rem"
      class="text-right"
      ref="dt"
    >
      <Column
        field="network"
        header="الشبكة"
        sortable
        style="width: 5%"
        class="text-center px-4 py-1 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4 text-center"
      >
        <template #body="{ data }">
          <span class="capitalize" v-tooltip.left="data.network"
            ><font-awesome-icon
              :icon="`fa-brands fa-${checkIcon(data.network)}`"
              :class="`text-primary text-3xl my-2 ${data.network}-color`"
          /></span>
        </template>
      </Column>
      <Column
        field="text"
        header="الاسم"
        sortable
        style="width: 47%"
        class="text-right px-4 py-1 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4"
      >
        <template #body="{ data }">
          <span
            v-tooltip.top="{
              value: data.text,
              pt: {
                text: 'text-sm text-right min-w-[300px]',
              },
            }"
            severity="secondary"
            >{{ getExcerpt(data.text, 18) }}</span
          >
        </template>
      </Column>
      <Column
        :field="dateFormater"
        header="التاريخ"
        sortable
        style="width: 5%"
        class="text-center px-4 py-1 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4 text-center"
      >
        <template #body="{ data }">
          <span>{{ formatDateTime(data.posted, "Do - MM - YYYY") }}</span>
        </template>
      </Column>
      <Column
        :field="popularityFileHandle"
        header="التفاعل"
        sortable
        style="width: 13%"
        class="text-center px-0 py-0 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4 text-center"
      >
        <template #body="{ data }">
          <div class="w-full py-2 border-b" v-tooltip.top="'عدد التفاعل الكلي'">
            <span>{{ shortenNumber(data.totalpopularity) }}</span>
          </div>
          <div class="w-full flex flex-row flex-nowrap">
            <div
              :class="[
                ' border-l flex flex-row flex-nowrap items-center justify-center px-2',
                { 'w-full': Object.keys(data.popularity).length === 1 },
                { 'w-1/2': Object.keys(data.popularity).length > 1 },
                { 'w-1/3': Object.keys(data.popularity).length > 2 },
              ]"
              v-for="(item, index) in data.popularity"
              :key="index"
              v-tooltip.top="getCustomOption(item.name, 'translation')"
            >
              <span class="py-1">
                <font-awesome-icon
                  :icon="getCustomOption(item.name, 'icon')"
                  :class="[
                    'text-md me-1 relative top-[1px]',
                    getCustomOption(item.name, 'class'),
                  ]"
                />
              </span>
              <span class="py-1">
                {{ shortenNumber(item.count) }}
              </span>
            </div>
          </div>
        </template>
      </Column>
      <Column
        :field="sentimentTranslation"
        header="المشاعر"
        sortable
        style="width: 5%"
        class="text-center px-4 py-1 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4 text-center"
      >
        <template #body="{ data }">
          <span v-tooltip.left="getCustomOption(data.sentiment, 'translation')">
            <font-awesome-icon
              :icon="getCustomOption(data.sentiment, 'icon')"
              :class="['text-2xl', getCustomOption(data.sentiment, 'color')]"
            />
          </span>
        </template>
      </Column>
      <Column
        :field="typeTranslation"
        header="النوع"
        sortable
        style="width: 5%"
        class="text-center px-4 py-1 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4 text-center"
      >
        <template #body="{ data }">
          <span v-tooltip.left="getCustomOption(data.type, 'translation')">
            <font-awesome-icon
              :icon="getCustomOption(data.type, 'icon')"
              :class="['text-2xl']"
            />
          </span>
        </template>
      </Column>
      <Column
        :field="userFileHandle"
        header="المستخدم"
        style="width: 15%"
        class="text-center px-4 py-1 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4"
      >
        <template #body="{ data }">
          <a
            :href="data.user.url"
            target="_blank"
            class="bg-white rounded-md p-1"
          >
            <span class="text-md">
              {{ data.user.name }}
            </span>
            <font-awesome-icon
              icon="fa-solid fa-arrow-up-right-from-square"
              class="text-success-800 text-md ms-2"
            />
          </a>
        </template>
      </Column>
      <Column
        field="url"
        header="الرابط"
        style="width: 5%"
        class="text-center px-4 py-1 font-taj border users-table-header"
        headerClass="bg-primary text-white py-4"
      >
        <template #body="{ data }">
          <a :href="data.url" target="_blank" class="bg-white rounded-md p-1">
            <font-awesome-icon
              icon="fa-solid fa-arrow-up-right-from-square"
              class="text-danger-800 text-xl"
            />
          </a>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import {
  getStoredPosts,
  getPostsInitializer,
  getTopPosts,
  checkIcon,
  shortenNumber,
  getExcerpt,
  getCustomOption,
  formatDateTime,
} from "./helpers/helpers";

export default {
  name: "SocialPostsView",
  components: {
    DataTable,
    Column,
    Button,
  },
  setup() {
    const posts = ref(getPostsInitializer());
    const topPosts = ref(null);
    const dt = ref(null);
    const exportCSV = () => {
      if (dt.value) {
        dt.value.exportCSV();
      } else {
        console.error("DataTable reference is not yet available.");
      }
    };

    function sentimentTranslation(data) {
      switch (data) {
        case data:
          return getCustomOption(data["sentiment"], "translation");
        default:
          return "لا يوجد";
      }
    }
    function typeTranslation(data) {
      switch (data) {
        case data:
          return getCustomOption(data["type"], "translation");
        default:
          return "لا يوجد";
      }
    }
    function userFileHandle(data) {
      switch (data) {
        case data:
          return `${data["user"].name} - ${data["user"].url}`;
        default:
          return "لا يوجد";
      }
    }
    function popularityFileHandle(data) {
      const counts = data["popularity"];
      let output = ""; // Initialize output variable outside the switch
      switch (data) {
        case data:
          output += `العدد الكلي : ${data["totalpopularity"]} - `;
          break; // Exit the switch after adding totalpopularity

        default:
          return "لا يوجد"; // No need for break here because return exits the function
      }

      for (const key in counts) {
        output += `${getCustomOption(counts[key].name, "translation")}: ${
          counts[key].count
        } - `;
      }

      return output;
    }

    function dateFormater(data) {
      switch (data) {
        case data:
          return formatDateTime(data["posted"]);
        default:
          return "لا يوجد";
      }
    }

    onMounted(() => {
      getStoredPosts(posts.value);
      topPosts.value = getTopPosts(posts.value);
    });

    return {
      posts,
      topPosts,
      checkIcon,
      shortenNumber,
      getExcerpt,
      getCustomOption,
      formatDateTime,
      sentimentTranslation,
      typeTranslation,
      dateFormater,
      popularityFileHandle,
      userFileHandle,
      dt,
      exportCSV,
    };
  },
};
</script>
