<template>
  <div class="title">
    <h1 class="text-3xl">welcome to My Account</h1>
    <button @click="logout()" class="text-white bg-red-500 rounded-md p-2">
      logout
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MyAccountView",
  methods: {
    logout() {
      axios
        .post("/api/v1/token/logout")
        .then((response) => {
          const token = response.data.auth_token;
          axios.defaults.headers.common["Authorization"] = "";
          localStorage.removeItem("token");
          this.$store.commit("removeToken", token);
          this.$router.push("/");
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
