<template>
  <div class="post-content">
    <span class="text-xs" v-if="posted">
      <font-awesome-icon icon="fa-regular fa-clock" class="text-primary" />
      <span class="text-sm ms-1 relative -top-[1px] text-gray-400">{{
        formatDateTime(posted)
      }}</span>
    </span>
    <h3 class="font-bold text-md mb-2" v-if="postTitle">
      {{ postTitle }}
    </h3>

    <p class="text-gray-700 text-base text-[15px]" v-if="postText">
      {{ postText }}
    </p>
  </div>
</template>

<script>
import { formatDateTime } from "@/views/dashboard/helpers/helpers";
export default {
  name: "PostContent",
  props: {
    posted: String,
    postTitle: String,
    postText: String,
  },

  setup() {
    return { formatDateTime };
  },
};
</script>
