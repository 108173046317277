<template>
  <div class="sign-in h-screen flex items-center justify-center -m-5">
    <div class="font-taj text-[#333] form">
      <div class="flex fle-col items-center justify-center container mx-auto">
        <div class="grid md:grid-cols-2 items-center gap-4 max-w-7xl w-full">
          <div
            class="border border-gray-300 rounded-md p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto"
          >
            <form class="space-y-6" @submit.prevent="submitForm">
              <div class="mb-10">
                <h3 class="text-2xl font-extrabold">تسجيل الدخول</h3>
                <p class="text-sm mt-4">
                  قم بتسجيل الدخول إلى حسابك واستكشف عالمًا من الإمكانيات. رحلتك
                  تبدأ هنا.
                </p>
              </div>
              <div>
                <label class="text-sm mb-2 block">اسم المستخدم</label>
                <div class="relative flex items-center">
                  <input
                    name="username"
                    v-model="username"
                    type="text"
                    required
                    class="w-full text-sm border border-gray-300 px-4 py-3 rounded-md outline-[#333]"
                    placeholder="اسم المستخدم"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#bbb"
                    stroke="#bbb"
                    class="w-[18px] h-[18px] absolute left-4"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      cx="10"
                      cy="7"
                      r="6"
                      data-original="#000000"
                    ></circle>
                    <path
                      d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                      data-original="#000000"
                    ></path>
                  </svg>
                </div>
              </div>
              <div>
                <label class="text-sm mb-2 block">كلمة المرور</label>
                <div class="relative flex items-center">
                  <input
                    name="password"
                    v-model="password"
                    :type="passwordVisible ? 'text' : 'password'"
                    required
                    class="w-full text-sm border border-gray-300 px-4 py-3 rounded-md outline-[#333]"
                    placeholder="كلمة المرور"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#bbb"
                    stroke="#bbb"
                    class="w-[18px] h-[18px] absolute left-4 cursor-pointer"
                    viewBox="0 0 128 128"
                    @click="togglePasswordVisibility"
                  >
                    <path
                      d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                      data-original="#000000"
                    ></path>
                  </svg>
                </div>
              </div>
              <!-- <div class="flex items-center justify-between gap-2">
                <div class="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 shrink-0 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label for="remember-me" class="ml-3 block text-sm">
                    Remember me
                  </label>
                </div>
                <div class="text-sm">
                  <a
                    href="jajvascript:void(0);"
                    class="text-blue-600 hover:underline"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div> -->
              <div class="!mt-10">
                <button
                  class="w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-[#333] hover:bg-black focus:outline-none"
                >
                  الدخول
                </button>
              </div>
              <div class="errors text-danger" v-if="errors.length">
                <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
              </div>
              <!-- <p class="text-sm !mt-10 text-center">
                Don't have an account
                <a
                  href="javascript:void(0);"
                  class="text-blue-600 hover:underline ml-1 whitespace-nowrap"
                  >Register here</a
                >
              </p> -->
            </form>
          </div>
          <div class="hidden md:flex lg:h-[400px] md:h-[300px] max-md:mt-10">
            <img
              src="https://readymadeui.com/login-image.webp"
              class="w-full h-full object-cover"
              alt="Dining Experience"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
      errors: [],
      passwordVisible: false,
    };
  },
  methods: {
    submitForm() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");

      const formData = {
        username: this.username,
        password: this.password,
      };
      axios
        .post("/api/v1/token/login/", formData)
        .then((response) => {
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors
          ) {
            this.errors.push(
              "Unable to log in with provided credentials. Please check your username and password."
            );
          } else if (error.message) {
            console.log(JSON.stringify(error.message));
          } else {
            console.log(JSON.stringify(error));
          }
        });
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>
