<template>
  <div class="container">
    <div v-if="posts">
      <div class="flex flex-row justify-between pt-4">
        <h1 class="text-2xl font-semibold">
          المستخدمين الأكثر اشارة لــ "{{ searchQuery }}"
        </h1>
        <Button
          icon="pi pi-external-link me-2"
          label="تصدير"
          class="font-taj font-semibold bg-success ignore-element-class mb-5"
          @click="exportCSV($event)"
        />
      </div>
      <DataTable
        :value="topUsers"
        tableStyle="min-width: 50rem"
        class="text-right"
        ref="dt"
      >
        <Column
          field="name"
          header="الاسم"
          sortable
          style="width: 70%"
          class="text-right px-4 py-1 font-taj border users-table-header"
          headerClass="bg-primary text-white py-4"
        ></Column>
        <Column
          field="count"
          header="عدد مرات الظهور"
          sortable
          style="width: 15%"
          class="text-center px-4 py-1 font-taj border users-table-header"
          headerClass="bg-primary text-white py-4 text-center"
        ></Column>
        <Column
          field="network"
          header="الشبكة"
          sortable
          style="width: 5%"
          class="text-center px-4 py-1 font-taj border users-table-header"
          headerClass="bg-primary text-white py-4 text-center"
        >
          <template #body="{ data }">
            <span
              class="capitalize"
              v-tooltip.left="data.network"
              placeholder="Top"
              ><font-awesome-icon
                :icon="`fa-brands fa-${checkIcon(data.network)}`"
                :class="`text-primary text-3xl my-2 ${data.network}-color`"
            /></span>
          </template>
        </Column>
        <Column
          field="influence.count"
          header="المتابعين"
          sortable
          style="width: 5%"
          class="text-center px-4 py-1 font-taj border users-table-header"
          headerClass="bg-primary text-white py-4 text-center"
        ></Column>
        <Column
          field="url"
          header="الرابط"
          style="width: 5%"
          class="text-center px-4 py-1 font-taj border users-table-header"
          headerClass="bg-primary text-white py-4"
        >
          <template #body="{ data }">
            <a :href="data.url" target="_blank" class="bg-white rounded-md p-1">
              <font-awesome-icon
                icon="fa-solid fa-arrow-up-right-from-square"
                class="text-danger-800 text-xl"
              />
            </a>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import {
  getStoredPosts,
  getPostsInitializer,
  checkIcon,
} from "./helpers/helpers";
import { getTopUsers } from "./helpers/SocialUsers";
export default {
  name: "SocialUsersView",
  components: {
    DataTable,
    Column,
    Button,
  },
  setup() {
    const posts = ref(getPostsInitializer());
    const topUsers = ref(null);
    const searchQuery = ref(null);
    const dt = ref(null);
    const exportCSV = () => {
      if (dt.value) {
        dt.value.exportCSV();
      } else {
        console.error("DataTable reference is not yet available.");
      }
    };

    onMounted(() => {
      getStoredPosts(posts.value);
      topUsers.value = getTopUsers(posts.value);
      searchQuery.value = localStorage.getItem("searchQuery");
    });
    return {
      posts,
      getTopUsers,
      topUsers,
      checkIcon,
      dt,
      exportCSV,
      searchQuery,
    };
  },
};
</script>
