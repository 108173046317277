<template>
  <SelectButton
    v-model="value"
    :options="options"
    optionLabel="value"
    dataKey="value"
    aria-labelledby="custom"
    optionDisabled="constant"
    :class="`${value.value}-container`"
    :currentPostID="currentPostID"
    :currentPostNetwork="currentPostNetwork"
    :sentimentValue="sentimentValue"
    :handler="sentimentHandler"
  >
    <template #option="slotProps">
      <button
        class="py-2 px-4 text-md"
        @click="updateSentiment(slotProps.option, slotProps.option.value)"
      >
        <font-awesome-icon :icon="slotProps.option.icon" />
      </button>
    </template>
  </SelectButton>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import SelectButton from "primevue/selectbutton";
export default {
  name: "SentimentSelector",
  components: {
    SelectButton,
  },
  props: {
    sentimentValue: String,
    sentimentClass: {
      type: String,
      default: "neutral-container",
    },
    currentPostID: String,
    currentPostNetwork: String,
    sentimentHandler: Function,
  },
  setup(props) {
    const value = ref({});
    const options = ref([
      { icon: "fa-regular fa-face-smile", value: "positive", constant: false },
      { icon: "fa-regular fa-face-meh", value: "neutral", constant: false },
      { icon: "fa-regular fa-face-angry", value: "negative", constant: false },
    ]);
    // Function to update options based on sentiment value
    function updateOptions() {
      value.value.value = props.sentimentValue;
      options.value.forEach((option) => {
        if (option.value === props.sentimentValue) {
          option.constant = true;
        } else {
          option.constant = false;
        }
      });
    }
    // Function to update sentiment value and save to posts
    function updateSentiment(post, sentiment) {
      props.sentimentHandler(
        props.currentPostID,
        sentiment,
        props.currentPostNetwork
      );
      // Mark the selected option as constant
      options.value.forEach((option) => {
        requestAnimationFrame(() => {
          option.constant = option === post;
        });
      });
    }
    onMounted(() => {
      updateOptions();
    });

    watch(props, () => {
      updateOptions();
    });

    return { value, options, updateSentiment };
  },
};
</script>
