<template>
  <div
    class="relative w-full min-h-96 mb-5 group rounded-lg overflow-hidden shadow-lg ring-1 ring-gray-200"
  >
    <div :class="['title p-3 mb-3', color]">
      <h2
        class="text-lg font-semibold flex flex-row justify-start items-center"
      >
        <font-awesome-icon :icon="icon" class="me-2 text-xl" />
        {{ title }}
      </h2>
    </div>
    <div id="chart" class="w-full p-5">
      <apexchart
        type="pie"
        width="380"
        dir="rtl"
        :options="chartOptions"
        :series="series"
        class="text-5xl text-pink-500"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { getCustomOption } from "@/views/dashboard/helpers/helpers";
export default {
  name: "SentimentChart",
  props: {
    title: {
      type: String,
      default: "العنوان",
    },
    icon: {
      type: String,
      default: "fa-solid fa-globe",
    },
    color: {
      type: String,
      default: "bg-primary text-white",
    },
    sentimentSeries: {
      type: Array,
      required: true,
    },
    sentimentCategories: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const series = ref([]);
    const chartOptions = ref({
      chart: {
        width: "100%",
        type: "pie",
        fontFamily: "Tajawal, sans-serif",
        offsetX: -110,
        offsetY: 0,
        // colors: ["#2E93fA", "#66DA26", "#00f"],
        // foreColor: "#00f",
      },
      labels: [],
      colors: ["#3B71CA", "#31AF63", "#e3342f"],
      plotOptions: {
        pie: {
          expandOnClick: true,
          offsetX: -30,
          offsetY: 40,
          customScale: 1,
          dataLabels: {
            offset: -25,
            minAngleToShowLabel: 10,
          },
        },
      },
      legend: {
        position: "right",
        fontSize: "18px",
        offsetX: -40,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });
    function updateOptions() {
      series.value = props.sentimentSeries;
      const sentimentCategories = getCustomOption(
        props.sentimentCategories,
        "translation"
      );
      chartOptions.value.labels = sentimentCategories;
    }

    onMounted(() => {
      updateOptions();
    });
    watch(props, () => {
      updateOptions();
    });
    return {
      series,
      chartOptions,
      updateOptions,
      getCustomOption,
    };
  },
};
</script>
