import { computed } from "vue";

/**
 * Computed property to calculate the total count of data items across all containers.
 * @param {Object} posts - The reactive ref object containing posts data.
 * @returns {number} The total count of data items.
 * @version 1.0
 */
const postCounts = (posts) =>
  computed(function () {
    // Calculate the sum of data items across all containers
    const sum = Object.values(posts.value).reduce((total, container) => {
      // Get the count of data items in the current container
      const count = container.data.length;
      // Add the count to the total
      return total + count;
    }, 0);
    return sum; // Return the total count
  });

/**
 * Retrieves stored posts from localStorage for each container and updates the posts object accordingly.
 * @param {Array} containers - An array containing the names of the containers.
 * @param {Object} posts - The reactive ref object containing posts data.
 */
function retrieveStoredPosts(containers, posts) {
  containers.forEach((container) => {
    // Retrieve stored posts from localStorage for the current container
    const storedPosts = localStorage.getItem(container);
    // Check if storedPosts is not null, undefined, or empty
    if (storedPosts && storedPosts !== undefined && storedPosts !== null) {
      try {
        // Attempt to parse storedPosts as JSON
        const parsedPosts = JSON.parse(storedPosts);
        // Check if the current container is not defined in the posts object
        if (!posts.value[container]) {
          // If not defined, create a new entry in the posts object with parsedPosts as data
          posts.value[container] = { data: parsedPosts };
        } else if (!posts.value[container].data) {
          // If data property is not defined in the current container, set it to parsedPosts
          posts.value[container].data = parsedPosts;
        }
      } catch (error) {
        console.error(`Error parsing stored posts for ${container}:`, error);
      }
    } else {
      console.warn(`No stored posts found for ${container}`);
    }
  });
}

/**
Extracts data from an array of posts based on the specified data property.
@param {Object} posts - Array of posts.
@param {string} dataProperty - The property of the post object from which data should be extracted.
@param {boolean} [dataUnique=true] - Flag indicating whether to return unique data items or total count.
@param {boolean} [dataContent=false] - Flag indicating whether to return the data list or the total count.
@returns {Array|number} - Depending on the options, returns an array of unique data items or the total count.
*/
function extractData(
  posts,
  dataProperty,
  dataUnique = true,
  dataContent = false
) {
  const dataList = [];
  Object.values(posts.value).forEach((container) => {
    Object.values(container.data).forEach((post) => {
      // Extract data from the specified property of the post object
      const data =
        dataProperty === "user" ? post[dataProperty]?.name : post[dataProperty];
      if (data !== undefined) {
        // Check if the data item already exists in the dataList
        const existingItem = dataList.find((item) => item.name === data);
        if (existingItem) {
          existingItem.count++;
        } else {
          // If the data item doesn't exist, create a new item
          const newItem = { name: data, count: 1 };
          if (dataProperty === "user") {
            // Include additional properties for user like image and url if available
            if (post.image) newItem.image = post.image;
            if (post.url) newItem.url = post.url;
          }
          dataList.push(newItem);
        }
      }
    });
  });

  if (dataContent) {
    return dataList;
  } else {
    return dataUnique
      ? dataList.length // Return the number of unique data items
      : dataList.reduce((total, item) => total + item.count, 0); // Return the total count
  }
}

/**
Extracts either counts or names from an array of posts based on the specified property.
@param {Object} posts - Array of posts.
@param {string} postsProperty - The property of the post object from which data should be extracted.
@param {boolean} [count=true] - Flag indicating whether to extract counts (true) or names (false).
@returns {Array} - Depending on the options, returns an array of counts or names.
*/
function extractDataOnlyCountsOrNames(posts, postsProperty, count = true) {
  const listOfCounts = [];
  const listOfNames = [];
  const list = extractData(posts, postsProperty, false, true); // Extract data from the posts based on the specified property
  if (count) {
    // Iterate through the list and push counts into the listOfCounts array
    list.forEach((item) => {
      listOfCounts.push(item.count);
    });
    return listOfCounts;
  } else {
    // Iterate through the list and push names into the listOfNames array
    list.forEach((item) => {
      listOfNames.push(item.name);
    });
    return listOfNames;
  }
}

function calculateSum(posts, dataProperty, getSum = true) {
  let total = 0;
  const popularityList = [];
  Object.values(posts.value).forEach((container) => {
    Object.values(container.data).forEach((post) => {
      // Check if the property exists before iterating over it
      if (post[dataProperty]) {
        post[dataProperty].forEach((item) => {
          const existingItemIndex = popularityList.findIndex(
            (p) => p.name === item.name
          );
          if (existingItemIndex !== -1) {
            // If the name already exists in popularityList, update the count
            popularityList[existingItemIndex].count += item.count;
          } else {
            // If the name doesn't exist, add a new entry
            popularityList.push({ name: item.name, count: item.count });
          }
          // Add to the total count
          total += item.count;
        });
      }
    });
  });

  if (getSum) {
    // Return the array of counts
    return total;
  } else {
    // Return the array of names
    return popularityList;
  }
}

/**
Gets item counts based on a specified property from an array of posts.
@param {Object} posts - Array of posts.
@param {string} postsProperty - The property of the post object from which data should be extracted.
@returns {Array} - An array of objects containing item counts and related information.
*/
function getItemCounts(posts, postsProperty) {
  const tagMap = new Map(); // Initialize a Map to store tags and their counts
  // Extract tags array containing names and URLs
  const tagsArray = extractDataOnlyCountsOrNames(posts, postsProperty, false);
  // Iterate through the flattened tags array
  tagsArray.flat().forEach((tag) => {
    const { text, url } = tag;
    // If the tag is not in the Map, add it with a count of 1
    if (!tagMap.has(text)) {
      tagMap.set(text, { name: text, url, count: 1 });
    } else {
      // If the tag exists in the Map, update its count
      const existingTag = tagMap.get(text);
      tagMap.set(text, { ...existingTag, count: existingTag.count + 1 });
    }
  });
  // Sort the tags by count in descending order
  const sortedTags = Array.from(tagMap.values()).sort(
    (a, b) => b.count - a.count
  );
  // Return the sorted array of tags with counts
  return sortedTags;
}

function countPostsByDay(posts) {
  // Check if the 'posts' object is defined
  if (posts.value) {
    const counts = {};
    // Iterate over each post type
    Object.values(posts.value).forEach((postType) => {
      // Check if the postType.data array is defined
      if (postType && Array.isArray(postType.data)) {
        // Iterate over each post in the postType.data array
        postType.data.forEach((post) => {
          if (post.posted) {
            const parsedDate = parseDate(post.posted);
            // Extract the day from the 'posted' property
            const postedDay = new Date(parsedDate).toLocaleDateString("en-US", {
              weekday: "long",
            });

            // Increment the count for the current day
            counts[postedDay] = (counts[postedDay] || 0) + 1;
          }
        });
      }
    });

    return weekDaysOrder(counts);
  }

  // Return an empty object if 'posts' is not defined
  return {};
}

/**
Parses a date string into a JavaScript Date object.
@param {string} dateString - The date string to parse (in the format "YYYY-MM-DD HH:MM:SS").
@returns {Date} - A JavaScript Date object representing the parsed date and time.
*/
function parseDate(dateString) {
  const parts = dateString.split(" "); // Split the date string into date and time parts
  const datePart = parts[0];
  const timePart = parts[1];
  // Extract year, month, and day from the date part
  const dateParts = datePart.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed in JavaScript
  const day = parseInt(dateParts[2]);
  // Extract hours, minutes, and seconds from the time part
  const timeParts = timePart.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);
  // Create a new Date object using the extracted date and time components
  return new Date(year, month, day, hours, minutes, seconds);
}
/**
Extracts keys or values from an object representing counts of posts by day.
@param {Object} dayObj - An object representing counts of posts by day.
@param {boolean} [getKeys=true] - A boolean indicating whether to extract keys (true) or values (false).
@param {Method} extractedDataFunction - An function representing what function to extract data.
@returns {Array} - An array containing either the keys or values extracted from the input object.
*/
function extractKeysAndValues(dayObj, extractedDataFunction, getKeys = true) {
  // Count the number of posts for each day
  const obj = extractedDataFunction(dayObj);
  // Initialize arrays to store keys and values
  const keys = [];
  const values = [];

  // Iterate through the object properties
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Push the key and value to the respective arrays
      keys.push(key);
      values.push(obj[key]);
    }
  }
  // Return either the keys or values based on the 'getKeys' parameter
  if (getKeys) {
    return keys;
  } else {
    return values;
  }
}

/**
Orders the weekdays in an object according to a predefined order.
@param {Object} weekdaysObject - An object containing counts or data indexed by weekdays.
@returns {Object} - An object with weekdays sorted based on a predefined order.
*/
function weekDaysOrder(weekdaysObject) {
  // Array representing the order of weekdays
  const weekdaysOrder = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Sort the keys of the object based on the order array
  const sortedWeekdays = Object.fromEntries(
    Object.entries(weekdaysObject).sort(
      ([day1], [day2]) =>
        weekdaysOrder.indexOf(day1) - weekdaysOrder.indexOf(day2)
    )
  );
  return sortedWeekdays;
}
/**
 * Counts the number of posts for each two-hour interval in a day.
 * @param {Object} posts - Object containing post data.
 * @returns {Object} - Object with keys representing two-hour intervals and values representing post counts, ordered by time.
 */
function countPostsByTwoHourInterval(posts) {
  // Check if the 'posts' object is defined
  if (posts.value) {
    const counts = {};
    // Iterate over each post type
    Object.values(posts.value).forEach((postType) => {
      // Check if the postType.data array is defined
      if (postType && Array.isArray(postType.data)) {
        // Iterate over each post in the postType.data array
        postType.data.forEach((post) => {
          if (post.posted) {
            const parsedDate = parseDate(post.posted);
            // Extract the hour from the 'posted' property
            let hour = parsedDate.getHours();
            // Calculate the two-hour interval
            const intervalStart = Math.floor(hour / 2) * 2;
            const intervalEnd = (intervalStart + 2) % 24;
            const intervalKey = `${formatHour(intervalStart)} - ${formatHour(
              intervalEnd
            )}`;
            // Increment the count for the current interval
            counts[intervalKey] = (counts[intervalKey] || 0) + 1;
          }
        });
      }
    });
    // Order the counts object by the starting time of each interval
    const sortedCounts = {};
    Object.keys(counts)
      .sort((a, b) => {
        // Extract the start hours from the interval keys
        const startHourA = parseInt(a.split("-")[0].split(":")[0]);
        const startHourB = parseInt(b.split("-")[0].split(":")[0]);
        return startHourA - startHourB;
      })
      .forEach((key) => {
        sortedCounts[key] = counts[key];
      });

    return sortedCounts;
  }

  // Return an empty object if 'posts' is not defined
  return {};
}

/**
 * Formats the hour in 24-hour format.
 * @param {number} hour - Hour to format (0-23).
 * @returns {string} - Formatted hour in 24-hour format.
 */
function formatHour(hour) {
  // Add leading zero if hour is single digit
  const formattedHour = hour < 10 ? `0${hour}` : hour;
  return `${formattedHour}:00`;
}

export {
  postCounts,
  retrieveStoredPosts,
  extractData,
  calculateSum,
  extractDataOnlyCountsOrNames,
  getItemCounts,
  countPostsByDay,
  parseDate,
  extractKeysAndValues,
  weekDaysOrder,
  countPostsByTwoHourInterval,
};
