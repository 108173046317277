<template>
  <div
    :class="{
      'absolute top-0 right-0': postImage,
      'types flex w-full px-6 pt-4 z-10': true,
    }"
  >
    <span
      class="text-xs text-danger bg-danger-100 inline-block rounded-sm px-2 py-1 me-1"
      v-if="postType"
    >
      <font-awesome-icon :icon="getCustomOption(postType, 'icon')" />
      <span class="ms-1 text-sm">{{
        getCustomOption(postType, "translation")
      }}</span>
    </span>
    <span
      class="text-sm text-primary bg-primary-100 inline-block rounded-sm px-3 py-1 me-1 uppercase"
      v-if="postLang"
    >
      {{ postLang }}
    </span>
  </div>
</template>

<script>
import { getCustomOption } from "@/views/dashboard/helpers/helpers";
export default {
  name: "PostType",
  props: {
    postImage: String,
    postType: String,
    postLang: String,
  },

  setup() {
    return { getCustomOption };
  },
};
</script>
