import axios from "axios";
import { setItemInLocalStorage } from "./helpers";
/**
 * Fetches data from the specified URL using Axios and assigns it to the provided target.
 * @param {String} url - The URL from which to fetch the data.
 * @param {Object} target - The target object where the retrieved data will be assigned.
 * @param {String} property - The property of the target object where the retrieved data will be stored.
 */
function fetchDataAndAssignToTarget(url, target, property) {
  axios
    .get(url)
    .then((response) => {
      target[property] = response.data[0][property];
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
}

/**
 * Retrieves posts from the specified network and stores them in the specified container.
 * @param {Vue} vm - The Vue component instance.
 * @param {string} identifier - The identifier for the API endpoint.
 * @param {string} container - The container to store the posts.
 * @param {string} network - The network from which to retrieve posts.
 * @param {string} q - The search query.
 */
function getPosts(vm, identifier, network, container, q) {
  const apiUrl = `api/proxy/${identifier}/`; // Proxy URL

  const params = {
    q: q,
    limit: 20,
    network: network,
  };

  // Set loading to true before making the request
  vm.posts[container].loading = true;

  axios
    .get(apiUrl, { params })
    .then((response) => {
      vm.posts[container].data = response.data.posts;

      // Save search query to local storage
      setItemInLocalStorage("searchQuery", q);

      if (
        vm.posts[container] &&
        vm.posts[container] !== undefined &&
        response.data.posts
      ) {
        // Save posts to local storage
        setItemInLocalStorage(container, JSON.stringify(response.data.posts));
      } else {
        setItemInLocalStorage(container, JSON.stringify([]));
      }
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    })
    .finally(() => {
      // Set loading to false after the request is completed
      vm.posts[container].loading = false;
    });
}

/**
 * Asynchronously fetches  posts from the premium network and stores them in the specified container.
 * @param {Vue} vm - The Vue component instance.
 * @param {string} identifier - The identifier for the API endpoint.
 * @param {string} network - The network from which to retrieve premium posts.
 * @param {string} container - The container to store the premium posts.
 * @param {string} q - The search query.
 */
async function getPremiumPosts(vm, identifier, network, container, q, counts) {
  try {
    // Step 1: Make initial request to retrieve request ID
    const apiUrl = `api/proxy/${identifier}/`;
    const params = {
      q: q,
      network: network,
      limit: 20,
    };
    vm.posts[container].loading = true;
    const response1 = await axios.get(apiUrl, { params });
    vm.posts[container].requestId = response1.data.meta.requestid;
    // Step 2: Wait for 20-60 seconds
    await new Promise((resolve) =>
      setTimeout(resolve, 20000 + Math.random() * 40000)
    );

    // Step 3: Fetch results using request ID
    let response2 = await axios.get(apiUrl, {
      params: {
        requestid: vm.posts[container].requestId,
        page: 0,
        limit: 20,
      },
    });
    let counter = 0;
    // Step 4: Retry fetching until status is "finished" or maximum retries reached
    while (response2.data.meta.status !== "finished" && counter < 2) {
      console.log(
        `${response2.data.meta.status} No. ${
          counter + 1
        } for newtork ${network}`
      );
      vm.posts[container].loading = true;
      // Retry after 30 seconds
      await new Promise((resolve) => setTimeout(resolve, 30000));
      response2 = await axios.get(apiUrl, {
        params: {
          requestid: vm.posts[container].requestId,
          page: 0,
          limit: 20,
        },
      });
      // vm.finished = false;
      counter++;
    }
    // Step 5: Assign fetched posts to data property
    vm.posts[container].data = response2.data.posts;
    vm.posts[container].loading = false;
    counts.value += 1;
    console.log(counts.value);

    // Step 6: Save posts to local storage if available
    if (
      vm.posts[container] &&
      vm.posts[container] !== undefined &&
      response2.data.posts
    ) {
      // Save posts to local storage
      localStorage.setItem(container, JSON.stringify(response2.data.posts));
      localStorage.setItem("counts", counts.value);
    } else {
      localStorage.setItem(container, JSON.stringify([]));
    }
  } catch (error) {
    console.error(error);
  }
}

export { fetchDataAndAssignToTarget, getPosts, getPremiumPosts };
