import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import "./assets/css/main.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
  faTwitter,
  faFacebook,
  faYoutube,
  faSquareTumblr,
  faTumblr,
  faFlickr,
  faRedditAlien,
  faDailymotion,
  faVimeo,
  faVk,
  faInstagram,
  faLinkedin,
  faInternetExplorer,
  faReadme,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faVideo,
  faQuoteLeft,
  faLink,
  faHeart,
  faRepeat,
  faArrowUpRightFromSquare,
  faUsers,
  faCircleInfo,
  faShareNodes,
  faSquareShareNodes,
  faRetweet,
  faPlus,
  faChartPie,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faPenToSquare,
  faImage,
  faComment,
  faEye,
  faPaste,
  faUser,
  faFaceSmile,
  faFaceAngry,
  faFaceMeh,
  faNoteSticky,
  faBookmark,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";
/* add icons to the library */
library.add(
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faSquareTumblr,
  faTumblr,
  faFlickr,
  faRedditAlien,
  faDailymotion,
  faVimeo,
  faVk,
  faGlobe,
  faClock,
  faVideo,
  faImage,
  faQuoteLeft,
  faLink,
  faPenToSquare,
  faHeart,
  faComment,
  faEye,
  faRepeat,
  faArrowUpRightFromSquare,
  faUsers,
  faCircleInfo,
  faShareNodes,
  faSquareShareNodes,
  faRetweet,
  faLinkedin,
  faPaste,
  faUser,
  faFaceSmile,
  faPlus,
  faInternetExplorer,
  faFaceAngry,
  faFaceMeh,
  faNoteSticky,
  faReadme,
  faBookmark,
  faChartPie,
  faTags,
  faCalendar
);
axios.defaults.baseURL = "https://s.testsie.com";

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .directive("tooltip", Tooltip)
  .use(store)
  .use(router, axios)
  .use(VueApexCharts)
  .use(PrimeVue)
  .mount("#app");
