<template>
  <div class="mt-3">
    <InputGroup dir="rtl" class="font-taj">
      <InputText
        placeholder="ابدء البحث"
        dir="rtl"
        v-model="searchQuery"
        class="font-taj"
        @keydown.enter="handleSearch"
      />
      <Button label="البحث" dir="rtl" @click="handleSearch" class="font-taj" />
    </InputGroup>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import InputGroup from "primevue/inputgroup";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
export default {
  name: "SearchBar",
  components: {
    InputGroup,
    InputText,
    Button,
  },
  props: {
    query: String,
    results: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const searchQuery = ref("");

    const handleSearch = () => {
      props.results(searchQuery.value);
    };

    onMounted(() => {
      searchQuery.value = props.query;
    });
    watch(
      () => props.query,
      (newValue) => {
        searchQuery.value = newValue;
      }
    );
    return { searchQuery, handleSearch };
  },
};
</script>
